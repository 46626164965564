import React, { Component } from "react";
import SectionHead from "pages/app/components/sectionHead";
import { withRouter, Link } from "react-router-dom";
import UsersIndex from "./users";
import SiteIndex from "./site";
import ProfileIndex from "./profile";
import { getSubRole } from "utils";
import { A4P_VENDOR_TYPE } from "data/config/variables";
import SettingsDomainsIndex from "./domains";

class SettingsIndex extends Component {
    constructor(props) {
        super(props);
        this.settingsMenu = [];
    }

    render() {
        let menu = [];
        if (getSubRole() === A4P_VENDOR_TYPE.ROOT_ADMIN) {
            menu.push({ id: "users", path: "/app/settings/users", icon: "supervisor_account", title: "Users" });
            menu.push({ id: "domains", path: "/app/settings/domains", icon: "link", title: "Domains" });
            menu.push({ id: "site", path: "/app/settings/site", icon: "web", title: "Website" });
        }
        menu.push({ id: "profile", path: "/app/settings/profile", icon: "face", title: "My Profile" });

        const pt = this.props.location.pathname;
        let sel = menu.find(m => m.path === pt);
        if (!sel) sel = menu[0];

        return (
            <div className="mainc container-fluid">
                <SectionHead title="Settings" />
                <div>
                    <div className="">
                        <ul className="nav nav-tabs ">
                            {menu.map((item, i) => (
                                <li key={i} className="nav-item">
                                    <Link
                                        to={item.path}
                                        className={`nav-link px-5 ${sel.path === item.path ? "active" : ""}`}
                                    >
                                        <i className={`material-icons ${sel.path === item.path ? "text-secondary" : "text-primary"}`}>{item.icon}</i>
                                        {sel.path === item.path ? (
                                            <strong>{item.title}</strong>
                                        ) : (
                                            <span>{item.title}</span>
                                        )}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className= "p-4 border-left border-right border-bottom bg-white">
                        {sel.id === "users" ? (
                            <UsersIndex />
                        ) : sel.id === "site" ? (
                            <SiteIndex />
                        ) : sel.id === "domains" ? (
                            <SettingsDomainsIndex />
                        ) : sel.id === "profile" ? (
                            <ProfileIndex />
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(SettingsIndex);
