import React, { Component } from "react";
import BookAppointment from "pages/components/BookAppointment";
import { getSubDomain } from "utils";
import { getVendorSiteContent } from "./network";

export default class SubHome extends Component {
    constructor(props) {
        super(props);
        const subDomain = getSubDomain();
        this.state = {
            subDomain,
            header: "Header Section",
            body: "Body"
        };
        if (subDomain) {
            getVendorSiteContent(subDomain.id)
                .then(r => {
                    const [sd] = r.data.websites;
                    if (sd) {
                        const {
                            header_section: header,
                            body_section: body
                        } = sd;
                        this.setState({ header, body });
                    }
                })
                .catch(err => console.log(err));
        }
    }
    render() {
        const { subDomain, header, body } = this.state;
        return (
            <div>
                <div>
                    {/* #Banner */}
                    <div className="position-relative">
                        <img
                            className="img-fluid"
                            src="/img/vendor_banner.jpg"
                            alt="inner page bg"
                        />
                        <div className="position-absolute w-100 text-center text-white " style={{top: "40%"}}>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: header
                                }}
                            ></p>
                        </div>
                    </div>
                    {/*Services*/}
                    <section className=" py-4">
                        <div className="container">
                            <h3 className="text-center text-muted py-3">
                                Book an Appointment
                            </h3>
                            <div className="mt-4">
                                <BookAppointment
                                    vendor_id={subDomain.vendor_id}
                                />
                            </div>
                        </div>
                    </section>
                    {/*Body Section*/}
                    <section className="py-5">
                        <div
                            className="container my-4"
                            style={{ paddingBottom: "12em" }}
                        >
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: body
                                }}
                            ></p>
                        </div>
                    </section>
                    {/*Footer*/}
                </div>
            </div>
        );
    }
}
