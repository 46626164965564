import React, { Component } from "react";
import { sendOTP, verifyOTP } from "./network";
import { DEFAULT_COUNTRY_CODE } from "data/config/variables";

export default class MobileVerify extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.buttonIds = { sendOTP: "btnMVSendOTP", verifyOTP: "btnMVVerifyOTP", resendOTP: "btnMVReSendOTP" };
        this.state = {
            mobile: "",
            otp: "",
            sentOTP: false,
            showOTPStatus: false,
            isOTPValid: false
        };
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value.trim() });
    }

    onClick(e) {
        const { id } = e.target;
        const { mobile, otp } = this.state;

        if (mobile && id === this.buttonIds.sendOTP) {
            sendOTP(DEFAULT_COUNTRY_CODE + mobile)
                .then(r => {
                    console.log(r);
                    this.setState({ sentOTP: true });
                })
                .catch(err => console.log(err));
        } else if (mobile && otp && id === this.buttonIds.verifyOTP) {
            verifyOTP(DEFAULT_COUNTRY_CODE + mobile, otp)
                .then(r => {
                    console.log(r);
                    this.setState({ showOTPStatus: false, isOTPValid: true });
                    this.props.onVerify(mobile);
                })
                .catch(err => {
                    console.log(err);
                    this.setState({ showOTPStatus: true, isOTPValid: false });
                });
        }
    }

    render() {
        const { mobile, sentOTP, showOTPStatus, isOTPValid, otp } = this.state;
        return (
            <div className="form-group">
                <label>Mobile: </label>

                <div className="mb-2 d-flex">
                    <div className="w-75">
                        <div className="">
                            <input
                                name="mobile"
                                value={mobile}
                                className="form-control"
                                placeholder="Enter 10 digit Mobile No. ex: 9879879879"
                                onChange={this.onChange}
                                disabled={isOTPValid}
                            />
                        </div>
                    </div>
                    <div className=" w-25 ml-2 align-self-center">
                        {isOTPValid ? (
                            <span className="text-success">
                                <i className="fa fa-check mr-1"></i>
                                Verified!
                            </span>
                        ) : (
                            <button
                                id={this.buttonIds.sendOTP}
                                type="button"
                                className="btn btn-primary"
                                onClick={this.onClick}
                            >
                                Send OTP
                            </button>
                        )}
                    </div>
                </div>
                {sentOTP && !isOTPValid && (
                    <div className="mb-2  d-flex">
                        <div className="w-50">
                            <div className="">
                                <input
                                    name="otp"
                                    value={otp}
                                    className="form-control"
                                    placeholder="Enter OTP"
                                    onChange={this.onChange}
                                />
                            </div>
                        </div>
                        <div className=" ml-2">
                            <button
                                id={this.buttonIds.verifyOTP}
                                type="button"
                                className="btn btn-secondary"
                                onClick={this.onClick}
                            >
                                Verify
                            </button>
                        </div>
                    </div>
                )}
                {showOTPStatus &&
                    (isOTPValid ? (
                        <div className="">
                            <div className="p-1 alert-success">
                                <i className="fa fa-tick"></i>
                                <strong>Verified!</strong>
                            </div>
                        </div>
                    ) : (
                        <div className="">
                            <div className="p-1 alert-danger">
                                <strong className="mr-2">Error!</strong>
                                Invalid code
                            </div>
                        </div>
                    ))}
            </div>
        );
    }
}
