import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class BookAppointmentConfirmation extends Component {
    render() {
        return (
            <div>

                <div className="p-3 border">
                    <div className="container py-5 m-auto">
                        <div className="text-center">
                            <div className="icon icon--order-success svg add_bottom_15">
                                <svg xmlns="http://www.w3.org/2000/svg" width={72} height={72}>
                                    <g fill="none" stroke="#8EC343" strokeWidth={2}>
                                        <circle
                                            cx={36}
                                            cy={36}
                                            r={35}
                                            style={{
                                                strokeDasharray: "240px, 240px",
                                                strokeDashoffset: "480px"
                                            }}
                                        />
                                        <path
                                            d="M17.417,37.778l9.93,9.909l25.444-25.393"
                                            style={{
                                                strokeDasharray: "50px, 50px",
                                                strokeDashoffset: "0px"
                                            }}
                                        />
                                    </g>
                                </svg>
                            </div>
                            <h2 className="my-3">Thanks for your Order!</h2>
                            <p className="lead text-muted py-2">
                                You will receive a confirmation email with the order information and instructions
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
