// ennivronment specific config variables
const env_config = {
  development: {
    ENABLE_CONSOLE_LOG: true,
    ENABLE_REDUX_CHROME_EXT: false,
    TOKBOX_EMBED_ID: '36c4e759-9fc6-458f-ae6a-f0435db7ff4c',
    //SITE_URL: "https://dev.activ4pets.com",
    SITE_URL: "https://activdoctors.us",
    GQL_ENDPOINT: process.env.REACT_APP_DEV_GQL_EP
  },
  production: {
    ENABLE_CONSOLE_LOG: false,
    ENABLE_REDUX_CHROME_EXT: false,
    TOKBOX_EMBED_ID: 'e3ea05ad-b3cd-4061-afa2-dabdcc42ba6d',
    SITE_URL: "https://www.activ4pets.com",
    GQL_ENDPOINT: process.env.REACT_APP_LIVE_GQL_EP
  }
};

// common variables across environments
const config = {
  ...env_config[process.env.REACT_APP_ENV || 'development'],  // export NODE_ENV=development
  ENV: process.env.REACT_APP_ENV || 'development',
  API_ENDPOINT: '', // accessed via proxy 
  RECAPTCHA_KEY : process.env.REACT_APP_RECAPTCHA_KEY,
  GOOGLE_PLACES_KEY : process.env.REACT_APP_GOOGLE_PLACES_KEY
};

export default config;