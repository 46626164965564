import React, { Component } from "react";
import Auth, { AuthModes } from "../components/auth";

export default class login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectUrl: this.props.location.redirectUrl || "/app"
		};
	}

	render() {
		let authMode = this.props.location.pathname.includes("/login")
			? AuthModes.login
			: AuthModes.signup;

		if(this.props.location.search.includes("forgot="))
			authMode = AuthModes.changePW;
			
		return (
			<div className="whiteHeaderOffset mb-5">
				<div className="container">
					<div className="row col-sm-11 col-md-5 mx-auto">
						<Auth
							mode={authMode}
							redirectUrl={this.state.redirectUrl}
						/>
					</div>
				</div>
			</div>
		);
	}
}
