import React, { Component } from "react";
import MyEditor from "pages/components/form/Editor";
import FormElement from "pages/components/form/FormElement";
import SimpleReactValidator from "simple-react-validator";
import { getSiteDetails, setSiteDetails } from "./network";
import Button from "pages/components/form/Button";
import { GQL_ERROR_DUPLICATE } from "utils/HasuraParser";
import { profile_pic_types } from "data/config/variables";
import ProfilePic from "pages/components/form/ProfilePic";

export default class SettingsSiteIndex extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.validator = new SimpleReactValidator({});

        this.state = {
            recordId: null,
            className: null,
            logo: null,
            header: false,
            body: false,
            updating: false,
            error: null
        };
    }

    componentDidMount() {
        getSiteDetails()
            .then(r => {
                const [site] = r.data.websites;
                const {
                    id: recordId,
                    name,
                    logo,
                    header_section: header,
                    body_section: body
                } = site || {};
                this.setState({ recordId, name, logo, header, body });
            })
            .catch(r => console.log(r));
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onFormSubmit() {
        if (this.validator.allValid()) {
            const {
                recordId,
                name,
                logo,
                new_header,
                header,
                new_body,
                body
            } = this.state;

            this.setState({ updating: true });

            setSiteDetails(
                recordId,
                name,
                logo,
                new_header || header,
                new_body || body
            )
                .then(r => this.setState({ updating: false }))
                .catch(err => {
                    console.log(err);
                    if (err === GQL_ERROR_DUPLICATE) {
                        this.setState({
                            updating: false,
                            error: "The domain is already taken!"
                        });
                    }
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
        return false;
    }

    render() {
        const { name, logo, header, body, updating, error } = this.state;
        return (
            <div>
                <div className="mb-5">
                    <div className="text-muted p-2 h6 mb-3 border-bottom">
                        Your Website Content:
                    </div>
                    {error && (
                        <p className="mx-3 alert alert-danger">{error}</p>
                    )}
                    <div className="mt-3 col">
                        <label className="text-muted ">
                            Brand Logo[Optional]:
                        </label>
                        <div className="d-flex mt-2">
                            <ProfilePic
                                avatar={logo}
                                type={profile_pic_types.brandLogo}
                                width={280}
                                height={60}
                                onUpload={avatar =>
                                    this.setState({ logo: avatar })
                                }
                            />
                        </div>
                    </div>
                    <div className="mt-3 ">
                        <FormElement
                            className="col-12"
                            label="Brand Name"
                            placeHolder="ex: Happy Clincs"
                            name="name"
                            value={name}
                            onChange={this.onChange}
                            validator={this.validator}
                            validation="required"
                        />
                    </div>
                    <div className="mt-3 col">
                        <label className="text-muted ">Header Section:</label>
                        <MyEditor
                            content={header || ""}
                            // needs to be copied in a separate state to avoid continous updates
                            onContentChange={content =>
                                this.setState({ new_header: content })
                            }
                        />
                    </div>
                    <div className="mt-3 col">
                        <label className="text-muted ">Body Section: </label>
                        <MyEditor
                            content={body || ""}
                            // needs to be copied in a separate state to avoid continous updates
                            onContentChange={content =>
                                this.setState({ new_body: content })
                            }
                        />
                    </div>
                    <div className="mt-3 col">
                        <label className="text-muted ">Tips: </label>
                        <div>
                            <a
                                href="https://www.mail-signatures.com/articles/direct-link-to-hosted-image/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                How to generate image url for inserting images
                                above
                            </a>
                        </div>
                    </div>
                </div>

                {/* <p
                            className="mt-2"
                            dangerouslySetInnerHTML={{
                                __html: content
                            }}
                        ></p> */}

                <div className="col my-3">
                    <Button
                        btnCls="btn-outline-success px-5"
                        iconCls="fa-save"
                        value="Update"
                        onClick={this.onFormSubmit}
                        loading={updating}
                    />
                </div>
            </div>
        );
    }
}
