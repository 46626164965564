import React, { Component } from "react";
import { searchListings } from "./network";
import isEmpty from "utils/isEmpty";
import { withRouter } from "react-router-dom";
import BookingListingItem from "./ListingItem";

class BookAppointmentListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selLi: false,
            vendor_id: this.props.vendor_id,
            items: []
        };
    }

    componentDidMount() {
        const { vendor_id } = this.state;
        searchListings(vendor_id)
            .then(r => {
                const li = r.data.vendor_listings;
                if (!isEmpty(li)) {
                    this.setState({
                        items: [...this.state.items, ...li]
                    });

                    // if single listing then auto select
                    if (li.length === 1) {
                        this.props.onSelect(...li, true);
                    }
                } else this.props.onSelect(null);
            })
            .catch(err => {
                this.props.onSelect(null);
                console.log(err);
            });
    }

    render() {
        const { items } = this.state;

        return (
            <div>
                <div className="mt-2 px-2 border">
                    <div className="my-3">
                        <p className="h6 text-muted">Select Listing:</p>
                    </div>
                    <div className="row">
                        {items &&
                            items.map((item, k) => (
                                <div key={k} className="col-md-6 col-sm-12">
                                    <BookingListingItem
                                        item={item}
                                        onSelect={this.props.onSelect}
                                    />
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(BookAppointmentListing);
