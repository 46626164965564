import React, { Component } from "react";
import { getUsersList, createOrUpdateUser, deleteUser } from "./network";
import isEmpty from "utils/isEmpty";
import CRUDIndex from "pages/components/CRUD";
import { A4P_VENDOR_TYPE } from "data/config/variables";
import { getUserId, getSubRole } from "utils";

export default class SettingsUserIndex extends Component {
    constructor(props) {
        super(props);
        this.tableConfig = [
            { name: "first_name", title: "First Name", type: "text", visible: true, required: true },
            { name: "last_name", title: "Last Name", type: "text", visible: true, required: true },
            { name: "email", title: "Email", type: "text", visible: true, required: true },
            { name: "password", title: "Password", type: "password" }
        ];
        this.state = {
            tableData: null
        };
        this.onUpdateRow = this.onUpdateRow.bind(this);
        this.onDeleteRow = this.onDeleteRow.bind(this);
        this.fetchData();
    }

    fetchData() {
        getUsersList()
            .then(r => {
                const users = r.data.vendor_logins;
                if (!isEmpty(users)) {
                    // filter out self
                    const self = getUserId();
                    const li = users.filter(u => u.id !== self);
                    this.setState({ tableData: [...li] });
                }
            })
            .catch(r => console.log(r));
    }

    onDeleteRow(id) {
        console.log(id);
        return deleteUser(id)
            .then(r => {
                const { tableData } = this.state;
                const filteredItems = tableData.filter(i => i.id !== id);
                this.setState({ tableData: filteredItems }); 
            })
            .catch(err => console.log(err));
    }

    onUpdateRow(id, payload) {
        
        const { tableData } = this.state;
        payload.subrole = A4P_VENDOR_TYPE.USER; // hardcoded to user type
        
        // update records in db
        return createOrUpdateUser(id, payload)
            .then(r => {
                console.log(r);
                if (id) {
                    tableData.forEach((e, k) => {
                        if (e.id === id) {
                            tableData[k] = { id, ...payload };
                            return true;
                        }
                    }); 
                } else {
                    const [row] = r.data.insert_vendor_logins.returning;
                    const v = {
                        id: row.id,
                        ...payload
                    };
                    tableData.push(v);
                }
            })
            .catch(err => console.log(err));
    }

    render() {
        const { tableConfig } = this;
        const { tableData } = this.state; 
        return (
            <div className="mt-2">
                {
                    <CRUDIndex
                        tableData={tableData}
                        tableConfig={tableConfig}
                        onUpdateRow={this.onUpdateRow}
                        onDeleteRow={this.onDeleteRow}
                    />
                }
            </div>
        );
    }
}
