import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import SimpleReactValidator from "simple-react-validator";
import ReCaptcha from "pages/components/form/ReCaptcha";
import FormElement from "pages/components/form/FormElement";
import { vet_doc_specialities, A4P_ROLE_USER } from "data/config/variables";
import { GQL_ERROR_DUPLICATE } from "utils/HasuraParser";
import { signup } from "./network";
import MobileVerify from "pages/components/auth/MobileVerify";

class Signup extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onCaptchaSubmit = this.onCaptchaSubmit.bind(this);

        this.validator = new SimpleReactValidator({
            // language customization
            /*  messages: {
        required: 'Пакажыце сапраўдны кантактны нумар',
        email: 'That is not an email.',
        default: 'koollll '
      }  */
        });
        this.initialState = {
            role: this.props.role,
            type: this.props.type,
            first_name: "",
            last_name: "",
            password: "",
            gender: "",
            summary: null,
            mobile_phone: "",
            office_phone: "",
            email: "",
            isCaptchaValid: false,
            submitBtnTxt: "Submit",
            serverErrors: false, // for displaying errors from server
            serverErrorMessage: "", // error message frm server
            thankYou: false // determines if form got submitted successfully
        };
        this.state = { ...this.initialState };
    }

    onSelectChange(sel) {
        let s = sel.map(item => item.value);
        console.log(`Option selected:`, s);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value.trim() });
    }

    onFormSubmit(e) {
        e.preventDefault();
        //this.setState({ thankYou: true }); return;

        if (this.validator.allValid()) {
            this.setState({
                submitBtnTxt: "Please Wait...",
                serverErrors: false,
                serverErrorMessage: ""
            });

            signup(this.state)
                .then(r => {
                    this.props.onSignupComplete(this.state);
                    console.log("Form submitted successfully!");
                })
                .catch(err => {
                    console.log(err);
                    this.setState({
                        submitBtnTxt: "Submit",
                        serverErrors: true,
                        serverErrorMessage:
                            err === GQL_ERROR_DUPLICATE
                                ? "This email already exists in our System."
                                : "Sorry! Something went wrong. Please try again later."
                    });
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate(); // [validator] rerender to show messages for the first time
        }
    }

    onCaptchaSubmit(value) {
        this.setState({ isCaptchaValid: true });
    }

    render() {
        const warningBlock = () => (
            <div className="alert alert-danger">
                <strong className="mr-2">Error!</strong> {this.state.serverErrorMessage}
            </div>
        );

        return (
            <div>
                {this.state.serverErrors && warningBlock()}
                <form id="vsignup" method="post" onSubmit={this.onFormSubmit}>
                    <div className="row">
                        <FormElement
                            className="col-md-6"
                            name="first_name"
                            placeHolder="First Name"
                            state={this.state}
                            onChange={this.onChange}
                            validator={this.validator}
                            validation="required|alpha"
                        />

                        <FormElement
                            name="last_name"
                            className="col-md-6"
                            placeHolder="Last Name"
                            state={this.state}
                            onChange={this.onChange}
                            validator={this.validator}
                            validation="required|alpha"
                        />

                        <FormElement
                            name="email"
                            className="col-md-12"
                            placeHolder="Email"
                            state={this.state}
                            onChange={this.onChange}
                            validator={this.validator}
                            validation="required|email"
                        /> 

                        <div className="col-md-12">
                            <MobileVerify
                                onVerify={num => {
                                    this.setState({ mobile_phone: num });
                                }}
                            />
                            {this.validator.message("mobile_phone", this.state.mobile_phone, "required")}
                        </div>

                        <FormElement
                            name="password"
                            type="password"
                            className="col-md-12"
                            placeHolder="Password"
                            state={this.state}
                            onChange={this.onChange}
                            validator={this.validator}
                            validation="required|min:8"
                        />

                        <div className="col-md-12" style={{ overflow: "hidden" }}>
                            <div className="form-group">
                                <ReCaptcha onChange={this.onCaptchaSubmit} />
                                {this.validator.message("captcha", this.state.isCaptchaValid, "accepted")}
                            </div>
                        </div>
                    </div>

                    <div className="w-100 my-3">
                        <button type="submit" className="btn btn-primary btn-block" value="Submit">
                            {this.state.submitBtnTxt}
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

Signup.defaultProps = {
    role: A4P_ROLE_USER
};

Signup.propTypes = {
    onSignupComplete: PropTypes.func.isRequired,
    role: PropTypes.string,
    type: PropTypes.number
};

export default Signup;
