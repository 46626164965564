import { gqlSend, apiSend } from "utils/AxiosUtil";

// from user side
export const searchListings = vendor_id => {
    const q = {
        query: `query( $offset: Int, $limit: Int )
            {
              vendor_listings (where: {vendor_id: {_eq: "${vendor_id}"}}) {
                id, vendor_id, avatar, title, line1, line2, address, managers, timezone, consultations, created_at, updated_at
              }
            }`
    };
    return gqlSend(q);
};

// fetchBookings
export const fetchBookedSlots = id => {
    const q = {
        query: `
            {
                bookings(where: {vendor_listing_id: {_eq: ${id}}, slot: {_gt: "now()"}}) {
                id, slot, details
              }
            }`
    };
    return gqlSend(q);
}

// make payment
export const processPayment = data => {
    return apiSend(data, "/api/payment");
};

// search user by email
export const searchUserByEmail = email => {
    const q = {
        query: `
            {
                users(where: {email: {_eq: "${email}"}}) {
                    first_name, email, id, last_name, pic, phone
                  }
            }`
    };
    return gqlSend(q);
}