import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { getUserId, isSMDevice } from "utils";
import { A4P_VENDOR_ID, APP_IS_PETS_ENABLED } from "data/config/variables";

class AppSidebar extends Component {
    constructor(props) {
        super(props);

        // user side menu
        this.userMenu = [
            /* { path: "/app", icon: "dashboard", title: "Dashboard" }, */
            {
                path: "/app/my-appointments",
                icon: "date_range",
                title: "My Appointments"
            },
            {
                path: "/app/medical-records",
                icon: "storage",
                title: "Medical Records"
            },
            {
                path: "/app/my-profile",
                icon: "person",
                title: "My Profile"
            },
        ];

        // vendor side menu
        this.vendorMenu = [
            /* { path: "/app", icon: "dashboard", title: "Dashboard" }, */
            {
                path: "/app/appointments",
                icon: "date_range",
                title: "My Appointments"
            },
            { path: "/app/listings", icon: "dns", title: "My Listings" },
            { path: "/app/settings", icon: "settings", title: "Settings" },
        ];

        // add my pets for pets website
        if (APP_IS_PETS_ENABLED) {
            this.userMenu.push({
                path: "/app/my-pets",
                icon: "pets",
                title: "My Pets"
            });
        }
        // add phr for a4p vendor
        if (getUserId() === A4P_VENDOR_ID) {
            this.vendorMenu.push({
                path: "/app/users",
                icon: "account_box",
                title: "Users"
            });
        }

        if(isSMDevice()) {
        const logout = {path: "/app/logout", icon: "not_interested", title: "Logout"};
        this.vendorMenu.push(logout);
        this.userMenu.push(logout);
        }
    }

    render() {
        const {sideMenu, onSideMenuTogl} = this.props; 
        let pt = this.props.location.pathname;
        let menu =
            this.props.auth.user.role === "vendor"
                ? this.vendorMenu
                : this.userMenu;

        return (
            <div className="be-left-sidebar pt-2" id="sidemenu" style={{display: sideMenu?"block": "none"}}>
                <div className="left-sidebar-content">
                    <ul className="sidebar-elements p-0 m-0">
                        {menu.map((item, i) => (
                            <li key={i}>
                                <Link
                                    to={item.path}
                                    onClick={onSideMenuTogl}
                                    className={
                                        pt === item.path ||
                                        (i && pt.includes(item.path))
                                            ? "active"
                                            : "none"
                                    }
                                >
                                    <i className="material-icons">
                                        {item.icon}
                                    </i>
                                    {item.title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="footer">
                    <div className="my-2 text-gray">
                        <i
                            className="material-icons"
                            style={{ verticalAlign: "middle" }}
                        >
                            call
                        </i>
                        1-888-51-ACTIV (22848)
                    </div>
                    Copyright © 2020 Activ Doctors Online. All rights reserved.
                </div>
            </div>
        );
    }
}

export default withRouter(AppSidebar);
