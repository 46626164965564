import { getVendorId } from "utils";
import bcrypt from "bcryptjs";
import { gqlSend } from "utils/AxiosUtil";

export const getUsersList = () => {
    const q = {
        query: `{
                    vendor_logins(where: {vendor_id: {_eq: ${getVendorId()}}}, order_by: {created_at: desc}) {
                        id, first_name, last_name, email, pic
                    }
                }`
    };
    return gqlSend(q);
};

export const createOrUpdateUser = (id, data) => {
    const { first_name, last_name, email, password, subrole } = data;
    let encrytPW = password
        ? bcrypt.hashSync(password, bcrypt.genSaltSync(10))
        : null;

    const _s = `vendor_id: $vendor_id, subrole: $subrole, first_name: $first_name, last_name: $last_name, email: $email ${
        password ? ", password: $password" : ""
    } `;

    const subQuery = id
        ? `update_vendor_logins( where: {id: {_eq: $id}}, _set: {${_s}} ) {
        affected_rows
      }`
        : `insert_vendor_logins(objects: {${_s}}) {
        returning {id}
      }`;

    const q = {
        query: ` 
          mutation ($id: Int, $vendor_id: Int, $subrole: Int, $first_name: String, $last_name: String, $email: String, $password: String ) {
            ${subQuery}
          }`,
        variables: {
            id,
            vendor_id: getVendorId(),
            subrole,
            first_name,
            last_name,
            email,
            password: encrytPW
        }
    };

    return gqlSend(q);
};

export const deleteUser = id => {
    return gqlSend({
        query: ` 
                  mutation {
                      delete_vendor_logins(where: {id: {_eq: ${id}}}) {
                          affected_rows
                        }
                  }
                  `
    });
};

export const getDomainDetails = () => {
    const vendor_id = getVendorId();

    return gqlSend({
        query: `
        {
          websites(where: {vendor_id: {_eq: ${vendor_id}}}) {
            id, vendor_id, custom_domain, sys_domain, sub_domain
          }
        }`
    });
};

export const setDomainDetails = (id, sub_domain, custom_domain) => {
    const vendor_id = getVendorId();

    const _s = `vendor_id: $vendor_id, custom_domain: $custom_domain, sub_domain: $sub_domain`;

    const subQuery = id
        ? `update_websites( where: {id: {_eq: ${id}}}, _set: {${_s}} ) {
        affected_rows
      }`
        : `insert_websites(objects: {${_s}}) {
        returning {id}
      }`;

    const q = {
        query: ` 
      mutation ($vendor_id: Int, $sub_domain: String, $custom_domain: String ) {
        ${subQuery}
      }`,
        variables: {
            vendor_id,
            sub_domain,
            custom_domain
        }
    };

    return gqlSend(q);
};

export const getSiteDetails = () => {
  const vendor_id = getVendorId();

  return gqlSend({
      query: `
      {
        websites(where: {vendor_id: {_eq: ${vendor_id}}}) {
          id, name, logo, header_section, body_section
        }
      }`
  });
};

export const setSiteDetails = (id, name, logo, header, body) => {
  const vendor_id = getVendorId();

  const _s = ` name: $name, logo: $logo, header_section: $header, body_section: $body, vendor_id: $vendor_id `;

  const subQuery = id
      ? `update_websites( where: {id: {_eq: ${id}}}, _set: {${_s}} ) {
      affected_rows
    }`
      : `insert_websites(objects: {${_s}}) {
      returning {id}
    }`;

  const q = {
      query: ` 
        mutation ($vendor_id: Int, $name: String, $logo: String, $header: String, $body: String ) {
          ${subQuery}
        }`,
      variables: {
          vendor_id, name, logo, header, body
      }
  };

  return gqlSend(q);
};
