import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getSubDomain } from "utils";

class PublicFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subDomain: getSubDomain()
        };
    }

    renderPriv = () => (
        <div className="text-center">
            <small>
                <Link to="/privacy-policy/">Privacy Policy</Link> |{" "}
                <Link to="/terms-conditions/">Terms &amp; Conditions</Link> |
                Copyright 2012 - 2019 Activ4Pets LLC | All Rights Reserved
            </small>
        </div>
    );

    render() {
        const { subDomain } = this.state;
        return (
            <div>
                {subDomain ? (
                    <footer
                        className="bg-light"
                        style={{
                            position: "fixed",
                            width: "100%",
                            bottom: "0"
                        }}
                    >
                        <div className="container py-2">
                            {this.renderPriv()}
                        </div>
                    </footer>
                ) : (
                    <footer className="bg-light py-5">
                        <div className="container pt-3 pb-3">
                            <div className="row">
                                <div className="col-sm-3">
                                    <ul className="list-unstyled">
                                        <li className="pb-2">
                                            <strong>
                                                Services for Pet Parents
                                            </strong>
                                        </li>

                                        <li className="pb-2">
                                            <Link to="/vet-appointments/">
                                                Vet Appointments
                                            </Link>
                                        </li>
                                        <li className="pb-2">
                                            <Link to="/vet-house-call/">
                                                House Visit
                                            </Link>
                                        </li>
                                        <li className="pb-2">
                                            <Link to="/e-consultation/">
                                                E-Consultation
                                            </Link>
                                        </li>
                                        <li className="pb-2">
                                            <Link to="/talk-to-a-vet/">
                                                Talk to a Vet
                                            </Link>
                                        </li>
                                        <li className="pb-2">
                                            <Link to="/second-medical-opinion/">
                                                Second Medical Opinion
                                            </Link>
                                        </li>
                                        <li className="pb-2">
                                            <Link to="/pet-medical-records/">
                                                Medical Records
                                            </Link>
                                        </li>
                                        <li className="pb-2">
                                            <Link to="/download-app/">
                                                Download Mobile App
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-4">
                                    <ul className="list-unstyled">
                                        <li className="pb-2">
                                            <strong>Solutions for Vets</strong>
                                        </li>
                                        <li className="pb-2">
                                            <Link to="/vet-practice-management-solution/">
                                                Practice Management Solution
                                            </Link>
                                        </li>
                                        <li className="pb-2">
                                            <Link to="/become-activ4pets-vet/">
                                                Become A4P Vet
                                            </Link>
                                        </li>
                                        <li className="pb-2">
                                            <Link to="/partner-relief-vet/">
                                                Partner Relief Vet
                                            </Link>
                                        </li>
                                        <li className="pb-2">
                                            <Link to="/for-vet/">
                                                Join Us Now
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-3">
                                    <ul className="list-unstyled">
                                        <li className="pb-2">
                                            <strong>About</strong>
                                        </li>
                                        <li className="pb-2">
                                            <Link to="/about/">
                                                Our Company
                                            </Link>
                                        </li>
                                        <li className="pb-2">
                                            <Link to="/leadership-team/">
                                                Leadership Team
                                            </Link>
                                        </li>
                                        <li className="pb-2">
                                            <Link to="/our-partners/">
                                                Our Partners
                                            </Link>
                                        </li>
                                        <li className="pb-2">
                                            <Link to="/shelter-partnership/">
                                                Shelter Program
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-2">
                                    <ul className="list-unstyled">
                                        <li className="pb-2">
                                            <strong>Resources</strong>
                                        </li>
                                        <li className="pb-2">
                                            <Link to="/blog/">Blog</Link>
                                        </li>
                                        <li className="pb-2">
                                            <Link to="/faqs/">FAQs</Link>
                                        </li>
                                        <li className="pb-2">
                                            <Link to="/contact-us/">
                                                Contact
                                            </Link>
                                        </li>
                                        <li className="pb-2">
                                            <Link to="/news/">News</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="clearfix" />
                            </div>
                            <div className="row my-5">
                                <div className="col-sm-12 col-lg-4">
                                    <label htmlFor="subsEmail">
                                        Our Monthly Newsletter
                                    </label>
                                    <form className="form-inline">
                                        <div className="form-group mr-2 mb-2">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="subsEmail"
                                                placeholder="Email"
                                            />
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn mb-2 btn-secondary"
                                        >
                                            Submit
                                        </button>
                                    </form>
                                </div>

                                <div className="col-sm-6 col-lg-3 offset-lg-1">
                                    <div className="pt-2">
                                        Call Us :{" "}
                                        <a
                                            href="tel:855-PETDATA (738-3282)"
                                            className="text-black-50"
                                        >
                                            855-PETDATA (738-3282)
                                        </a>
                                    </div>
                                    <div className="pt-2">
                                        Mail Us :{" "}
                                        <a
                                            href="mailto:contact@activ4pets.com"
                                            className="text-black-50"
                                        >
                                            contact@activ4pets.com
                                        </a>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-lg-4 s-link mt-3">
                                    <a
                                        href="https://www.facebook.com/activ4pets"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fa fa-facebook" />
                                    </a>
                                    <a
                                        href="https://twitter.com/activ4pets"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fa fa-twitter" />
                                    </a>
                                    <a
                                        href="https://www.instagram.com/activ4pets/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fa fa-instagram" />
                                    </a>
                                    <a
                                        href="https://www.youtube.com/channel/UCJ9iktgi65y-Grzc2uaf9ew"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fa fa-youtube" />
                                    </a>
                                    <a
                                        href="https://www.linkedin.com/company/activ4pets"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fa fa-linkedin" />
                                    </a>
                                    <a
                                        href="https://www.pinterest.com/activ4pets/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <i className="fa fa-pinterest" />
                                    </a>
                                </div>
                                <div className="clearfix" />
                            </div>
                            {this.renderPriv()}
                        </div>
                    </footer>
                )}
            </div>
        );
    }
}

export default PublicFooter;
