import React, { Component } from "react";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";

import "react-dropzone-uploader/dist/styles.css";
import FormElement from "pages/components/form/FormElement";
import ProfilePic from "pages/components/form/ProfilePic";
import ChangePass from "./changepass";
import { updateUserProfile } from "pages/app/components/network";
import { updateUserInfo } from "data/actions/authActions";
import { profile_pic_types } from "data/config/variables";
import Button from "pages/components/form/Button";
import { removeLoginToken } from "utils/LoginChecker";

class MyProfile extends Component {
    constructor(props) {
        super(props);

        this.validator = new SimpleReactValidator({});

        const { user } = this.props;
        const { id, first_name, last_name, email, pic, role } = user || {};

        this.state = {
            id,
            first_name,
            last_name,
            email,
            pic,
            role,
            updating: false,
            showChangePass: false
        };

        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.toggleChangePass = this.toggleChangePass.bind(this);
    }

    // on form input field change
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    toggleChangePass() {
        this.setState({ showChangePass: !this.state.showChangePass });
    }

    onFormSubmit(e) {
        e.preventDefault();
        this.setState({ updating: true });

        if (this.validator.allValid()) {
            updateUserProfile(this.state)
                .then(r => {
                    console.log(r);
                    const { first_name, last_name, pic } = this.state;
                    this.props.updateUserInfo({ first_name, last_name, pic });
                    removeLoginToken();
                    this.setState({ updating: false });
                })
                .catch(err => console.log(err));
        } else {
            this.validator.showMessages();
            this.forceUpdate(); // [validator] rerender to show messages for the first time
        }
    }

    render() {
        const { pic, updating } = this.state;
        return (
            <div>
                <div className="">
                    {this.state.showChangePass ? (
                        <ChangePass onGoBack={this.toggleChangePass} />
                    ) : (
                        <div>
                            <div className="row justify-content-between">
                                <div className="col-sm-12 col-md-4 mb-3">
                                    <div className="d-flex mt-2 justify-content-center">
                                        <ProfilePic
                                            avatar={pic}
                                            type={profile_pic_types.userAvatar}
                                            onUpload={avatar => this.setState({ pic: avatar }, this.onUpdate)}
                                        />
                                    </div>
                                </div>
                            
                                <div className="col-sm-12 col-md-8 ">
                                    <FormElement
                                        name="first_name"
                                        label="First Name"
                                        state={this.state}
                                        onChange={this.onChange}
                                        validator={this.validator}
                                        validation="required"
                                    />

                                    <FormElement
                                        name="last_name"
                                        label="Last Name"
                                        state={this.state}
                                        onChange={this.onChange}
                                        validator={this.validator}
                                        validation="required"
                                    />

                                    <FormElement name="email" label="Email" disabled={true} state={this.state} />

                                    <div className="mt-4 pt-2">
                                        <Button
                                            btnCls="btn-outline-success px-5"
                                            value="Update"
                                            loading={updating}
                                            onClick={this.onFormSubmit}
                                        />
                                    </div>
                                </div>
                                </div>
                            <div className="my-2 text-primary text-right">
                                <a href="#!"  onClick={this.toggleChangePass}><strong>Change Password?</strong></a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
});

const mapDispatchToProps = dispatch => {
    return {
        updateUserInfo: data => dispatch(updateUserInfo(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
