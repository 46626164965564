import React, { Component } from "react";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "react-datepicker/dist/react-datepicker.css";
import "./table.css";
import FormElement from "../form/FormElement";
import { TIMEZONES } from "pages/components/roster/constants";
import BookAppointmentService from "./service";
import isEmpty from "utils/isEmpty";

export default class BookAppointmentSlot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedService: false,
            selectedDate: null,
            selectedTime: null,
            timezone: moment.tz.guess(),
            date: new Date(),
            selectedTime: null
        };
    }

    getTimeSlots(listing, bookedSlots) {
        const { selectedService, selectedDate, timezone: userTZ } = this.state;

        if (selectedService && selectedDate) {
            let allSlots = [];
            const selDt = moment(selectedDate).format("YYYY-MM-DD"); // to remove Timezone
            const { timezone: listingTZ } = listing;
            const { schedule, duration } = selectedService;

            // generate listing time slots for next2 days (to account for timezone)
            const selDt_listingTZ = moment.tz(selDt, userTZ).tz(listingTZ);

            [...Array(2)].forEach((_, i) => {
                const currD = selDt_listingTZ.add(i, "d").format("ddd");

                // filter schedule for rows containing the given day
                const filteredSchedule = schedule.filter(s => s.days.includes(currD));

                // for each row calc slots
                filteredSchedule.forEach(row => {
                    const st = moment.tz(`${selDt_listingTZ.format("YYYY-MM-DD")} ${row.from}`, listingTZ);
                    const et = moment.tz(`${selDt_listingTZ.format("YYYY-MM-DD")} ${row.to}`, listingTZ);
                    while (st.isBefore(et)) {
                        allSlots.push(st.format());
                        st.add(duration, "minute");
                    }
                });
            });

            // reset to current date
            const selDt_userTZ = moment.tz(selDt, userTZ);

            // filter applicable slots and exclude already booked slots
            const filteredSlots = allSlots.filter(
                ts => selDt_userTZ.isSame(moment.tz(ts, userTZ), "day") && !bookedSlots.includes(ts)
            );
            //console.log(allSlots, bookedSlots, filteredSlots);
            return filteredSlots;
        }
    }

    render() {
        const { selectedTime, timezone, selectedService, selectedDate } = this.state;
        const { listing, bookedSlots} = this.props;

        const slots = this.getTimeSlots(listing, bookedSlots);

        return (
            <div className="mt-2 px-2 border">
                <BookAppointmentService
                    listing={listing}
                    onSelect={sel => this.setState({ selectedService: sel, selectedDate: null, selectedTime: null })}
                />
                {selectedService && (
                    <div>
                        <div className="my-3">
                            <p className="h6 text-muted">Select Date and Time:</p>
                        </div>
                        <div>
                            <div className="row">
                                <div className="col-md-4 col-sm-12 my-2">
                                    <div style={{ width: "88%" }}>
                                        <FormElement className=" ">
                                            <div title="Select the timezone of your clinic">
                                                <Select
                                                    name="timezone"
                                                    options={TIMEZONES}
                                                    value={TIMEZONES.map(op =>
                                                        timezone.includes(op.value) ? op : false
                                                    )}
                                                    onChange={sel =>
                                                        this.setState({
                                                            timezone: sel.value,
                                                            selectedDate: null,
                                                            selectedTime: null
                                                        })
                                                    }
                                                />
                                            </div>
                                        </FormElement>
                                    </div>
                                    <div style={{ fontSize: "1.25em" }}>
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={date => this.setState({ selectedDate: date })}
                                            inline
                                            minDate={new Date()}
                                            maxDate={new Date().setDate(new Date().getDate() + 15)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-12 my-2">
                                    {selectedDate ? (
                                        <div className="d-flex flex-wrap justify-content-center">
                                            {isEmpty(slots) && (
                                                <p className="text-muted lead">No slots available for this date</p>
                                            )}
                                            {slots.map((t, k) => (
                                                <div
                                                    key={k}
                                                    className={`shadow-sm rounded  p-3 mb-3 mr-3 ${
                                                        selectedTime === t
                                                            ? "bg-primary text-white"
                                                            : "bg-white text-primary"
                                                    }`}
                                                    onClick={e => {
                                                        confirmAlert({
                                                            message: `Confirm ${moment(selectedDate).format(
                                                                "Do dddd, MMMM"
                                                            )} at ${moment.tz(t, timezone).format("hh:mm A")}?`,
                                                            buttons: [
                                                                {
                                                                    label: "Yes",
                                                                    onClick: () =>
                                                                        this.props.onSelect({
                                                                            timezone: timezone,
                                                                            time: t,
                                                                            service: selectedService
                                                                        })
                                                                },
                                                                {
                                                                    label: "No",
                                                                    onClick: () => console.log("escaped")
                                                                }
                                                            ]
                                                        });
                                                        this.setState({ selectedTime: t });
                                                    }}
                                                    style={{
                                                        height: "fit-content",
                                                        cursor: "pointer",
                                                        width: "28%"
                                                    }}
                                                >
                                                    <div className="text-center ">
                                                        {moment.tz(t, timezone).format("hh:mm A")}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <p className="text-muted lead">Please select a date</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
