import React, { Component } from "react";
import moment from "moment-timezone";
import FormElement from "pages/components/form/FormElement";
import Select from "react-select";
import { A4P_CONSULTATION_NAMES } from "data/config/variables";
import { DAYS2, TIMEZONES } from "pages/components/roster/constants";
import { _retBlank } from "utils";
import isEmpty from "utils/isEmpty";

export default class ManageLiConsultation extends Component {
    constructor(params) {
        super(params);

        this.validator = this.props.validator;

        const { data } = this.props;
        const { consultations, timezone } = data || {};

        this.state = {
            timezone: timezone || moment.tz.guess(),
            consultations: consultations || []
        };
        this.onUpdate = this.onUpdate.bind(this);
    }

    onUpdate() {
        const { timezone, consultations } = this.state;
        this.props.onUpdate({ timezone, consultations });
    }

    render() {
        const { timezone, consultations } = this.state;

        return (
            <div>
                <div className="">
                    <FormElement className="w-50">
                        <div title="Select the timezone of your clinic">
                            <label className="text-muted">Timezone: </label>
                            <Select
                                name="timezone"
                                options={TIMEZONES}
                                value={TIMEZONES.map(op => (timezone.includes(op.value) ? op : false))}
                                onChange={sel => this.setState({ timezone: sel.value }, this.onUpdate)}
                            />
                        </div>
                    </FormElement>

                    {isEmpty(consultations) && (
                        <p className="lead jumbotron">Click the button below to Add Consultation</p>
                    )}
                    {this.validator.message("Consultation", consultations, "required")}

                    {consultations &&
                        consultations.map((c, k) => (
                            <div key={k} className="bg-light p-4 d-flex justify-content-between align-items-start mt-4">
                                <div className="flex-fill mr-5">
                                    <div className="form-group custom-control custom-switch">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id={`switch${k}`}
                                            checked={c.status}
                                            onChange={e => {
                                                const sched = [...consultations];
                                                sched[k]["status"] = !sched[k]["status"];
                                                this.setState({ consultations: [...sched] }, this.onUpdate);
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor={`switch${k}`}>
                                            {c.status ? "Enabled" : "Disabled. (This listing wont be visible to users)"}
                                        </label>
                                    </div>

                                    <FormElement>
                                        <div title="Select Listing type">
                                            <label className="text-muted">Consultation Type: </label>
                                            <Select
                                                name="type"
                                                options={A4P_CONSULTATION_NAMES}
                                                value={
                                                    c.type &&
                                                    A4P_CONSULTATION_NAMES.map(op => (c.type === op.value ? op : false))
                                                }
                                                onChange={sel => {
                                                    const sched = [...consultations];
                                                    sched[k]["type"] = sel.value;
                                                    this.setState({ consultations: [...sched] }, this.onUpdate);
                                                }}
                                            />
                                        </div>
                                        {this.validator.message("Consultation Type", c.type, "required")}
                                    </FormElement>
                                    {c.status && (
                                        <div>
                                            <div className="d-flex justify-content-start">
                                                <FormElement
                                                    className="flex-fill mr-5"
                                                    name="duration"
                                                    label="Session Duration"
                                                    placeHolder="Duration in minutes"
                                                    value={c.duration}
                                                    onChange={e => {
                                                        const sched = [...consultations];
                                                        sched[k]["duration"] = e.target.value.trim();
                                                        this.setState({ consultations: [...sched] });
                                                    }}
                                                    validator={this.validator}
                                                    validation="required|numeric"
                                                />

                                                <FormElement
                                                    className="flex-fill ml-5"
                                                    name="price"
                                                    label="Session Price"
                                                    placeHolder="Price in INR / session"
                                                    value={c.price}
                                                    onChange={e => {
                                                        const sched = [...consultations];
                                                        sched[k]["price"] = e.target.value.trim();
                                                        this.setState({ consultations: [...sched] });
                                                    }}
                                                    validator={this.validator}
                                                    validation="required|numeric"
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label className="text-muted">Schedule: </label>

                                                {isEmpty(c.schedule) && (
                                                    <p className="mb-3 h6">
                                                        No schedules are added. Click below to add schedule.
                                                    </p>
                                                )}

                                                {c.schedule &&
                                                    c.schedule.map((s, k1) => (
                                                        <div key={k1} className="d-flex align-items-center">
                                                            <FormElement className="flex-fill mr-4">
                                                                <div title="Select Days">
                                                                    <label className="text-muted">Select Days: </label>
                                                                    <Select
                                                                        name="days"
                                                                        options={DAYS2}
                                                                        isMulti={true}
                                                                        placeholder="Select Day(s)"
                                                                        value={DAYS2.map(op =>
                                                                            s.days && s.days.includes(op.value)
                                                                                ? op
                                                                                : false
                                                                        )}
                                                                        onChange={sel => {
                                                                            const sched = [...consultations];
                                                                            sched[k]["schedule"][k1]["days"] =
                                                                                sel && sel.length > 0
                                                                                    ? sel.map(i => i.value)
                                                                                    : "";
                                                                            this.setState({
                                                                                consultations: [...sched]
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                                {this.validator.message("days", s.days, "required")}
                                                            </FormElement>

                                                            <FormElement
                                                                className=""
                                                                name="from"
                                                                label="From Time"
                                                                type="time"
                                                                value={s.from}
                                                                onChange={e => {
                                                                    const sched = [...consultations];
                                                                    sched[k]["schedule"][k1][
                                                                        "from"
                                                                    ] = e.target.value.trim();
                                                                    this.setState({ consultations: [...sched] });
                                                                }}
                                                                validator={this.validator}
                                                                validation="required"
                                                            />
                                                            <div className="mt-2 mx-2">--to--</div>
                                                            <FormElement
                                                                className=""
                                                                name="to"
                                                                label="End Time"
                                                                type="time"
                                                                value={s.to}
                                                                onChange={e => {
                                                                    const sched = [...consultations];
                                                                    sched[k]["schedule"][k1][
                                                                        "to"
                                                                    ] = e.target.value.trim();
                                                                    this.setState({ consultations: [...sched] });
                                                                }}
                                                                validator={this.validator}
                                                                validation="required"
                                                            />
                                                            
                                                                { k1!==0 && <div className="mt-2">
                                                                    <a
                                                                        className="h4 text-danger ml-3"
                                                                        href="#!"
                                                                        onClick={e => {
                                                                            const sched = [...consultations];
                                                                            sched[k]["schedule"].splice(k1, 1);
                                                                            this.setState({
                                                                                consultations: [...sched]
                                                                            });
                                                                        }}
                                                                    >
                                                                        <i className="fa fa-close"></i>
                                                                    </a>
                                                                </div>}
                                                            
                                                        </div>
                                                    ))}

                                                <div className="mt-3">
                                                    <button
                                                        className="btn btn-secondary px-4"
                                                        onClick={e => {
                                                            const sched = [...consultations];
                                                            if (!sched[k]["schedule"]) sched[k]["schedule"] = [];
                                                            sched[k]["schedule"].push({});
                                                            this.setState({ consultations: [...sched] });
                                                        }}
                                                    >
                                                        <i className="fa fa-plus mr-2"></i>Add Schedule
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="ml-5">
                                    <button
                                        className="btn btn-danger px-4"
                                        onClick={e => {
                                            const sched = [...consultations];
                                            sched.splice(k, 1);
                                            this.setState({ consultations: [...sched] }, this.onUpdate);
                                        }}
                                    >
                                        <i className="fa fa-remove mr-2"></i>Remove
                                    </button>
                                </div>
                            </div>
                        ))}

                    <div className="my-4 text-right">
                        <button
                            className="btn btn-warning px-4"
                            onClick={e => {
                                const sched = [...consultations];
                                sched.push({ status: true, schedule: [{}] });
                                this.setState({ consultations: [...sched] });
                            }}
                        >
                            <i className="fa fa-plus mr-2"></i>Add Consultation
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
