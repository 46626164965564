import React, { Component } from "react"; 
import FormElement from "pages/components/form/FormElement";
import ProfilePic from "pages/components/form/ProfilePic";
import Select from "react-select"; 
import { profile_pic_types } from "data/config/variables";
import { getUsersList } from "../../settings/network";
import isEmpty from "utils/isEmpty";

export default class ManageLiDetails extends Component {
    constructor(props) {
        super(props);

        this.validator = this.props.validator;

        const { data } = this.props;
        const { avatar, managers, title, line1, line2, address } = data || {};console.log(data)

        this.state = {
            avatar: avatar || null,
            allUsers: [],
            managers: managers || [],
            title: title || "",
            line1: line1 || "",
            line2: line2 || "",
            address: address || "",
        };
        this.onChange = this.onChange.bind(this);
        this.onUpdate = this.onUpdate.bind(this); 
    }

    componentDidMount() {
        getUsersList()
        .then(r => {
            const users = r.data.vendor_logins;
            if (!isEmpty(users)) {
                const allUsers = users.map(u=>{
                    return {value: u.id, label: `${u.first_name} ${u.last_name}`}
                });
                this.setState({allUsers});
            }
        })
        .catch(r => console.log(r));
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value }, this.onUpdate);
    }

    onUpdate() { 
        const { avatar, managers, title, line1, line2, address } = this.state;
        this.props.onUpdate({ avatar, managers, title, line1, line2, address });
    }

    render() {
        const { avatar, managers, allUsers } = this.state;
        return (
            <div>
                <div className="d-flex d-flex justify-content-between">
                    <div className="flex-grow-1 mr-5">
                        <FormElement
                            name="title"
                            label="Title"
                            placeHolder="Ex: Happy Hospitals"
                            title="Enter Clinic Name"
                            state={this.state}
                            onChange={this.onChange}
                            validator={this.validator}
                            validation="required"
                        />

                        <FormElement
                            label="Line 1"
                            name="line1"
                            placeHolder="Doctor Name or Certifications"
                            title="Enter Doctor name or degrees"
                            state={this.state}
                            onChange={this.onChange}
                            validator={this.validator}
                            validation="required"
                        />

                        <FormElement
                            label="Line 2 [Optional]"
                            name="line2"
                            placeHolder="Services provided"
                            title="Enter services provided at the place"
                            state={this.state}
                            onChange={this.onChange}
                        />

                        <FormElement
                            label="Address [Optional]"
                            name="address"
                            placeHolder="Ex: 18, Gandhi Bazaar, Bangalore - 50"
                            title="Enter location"
                            state={this.state}
                            onChange={this.onChange}
                        />

                        <FormElement
                            label="managers"
                            name="managers" 
                        >
                            <div title="Select users who can manage this listing and appointments">
                                <label className="text-muted">Users who can view this Listing: </label>
                                <Select
                                    name="managers"
                                    options={allUsers}
                                    isMulti={true}
                                    placeholder="Select User(s)"
                                    value={allUsers.map(op => (managers && managers.includes(op.value) ? op : false))}
                                    onChange={sel =>
                                        this.setState({
                                            managers: sel && sel.length > 0 ? sel.map(i => i.value) : ""
                                        }, this.onUpdate)
                                    }
                                />
                            </div>
                        </FormElement>
                    </div>
                    <div className="mx-5 align-self-center">
                        <ProfilePic 
                            avatar = {avatar}
                            type={profile_pic_types.vendorServicesAvatar}
                            onUpload={avatar => this.setState({avatar}, this.onUpdate)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
