import React, { Component } from 'react'
import AppointmentsIndex from 'pages/app/components/Appointments'

export default class VendorAppointments extends Component {
  render() {
    return (
      <AppointmentsIndex />
    )
  }
}
