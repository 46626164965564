import React, { Component } from "react";
import { Redirect } from "react-router";
import SimpleReactValidator from "simple-react-validator";
import FormElement from "pages/components/form/FormElement";
import {
	A4P_VENDOR_ID,
	A4P_SERVICE_TALKTOAVET,
	A4P_SERVICE_FEES,
	A4P_ROLE_USER,
	A4P_PAYMENT_TYPES
} from "data/config/variables";
import DoctorSchedule from "pages/components/Schedule";
import { setCheckoutData, getUserRole } from "utils";
import { searchServices } from "pages/public/components/network";
import moment from "moment";
import MyPets from "pages/components/MyPets";
import { fetchMyPlans } from "pages/public/checkout/network";

export default class TalkToVetRequest extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.onPetSelected = this.onPetSelected.bind(this);
		this.onTSSelected = this.onTSSelected.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);
		this.onFormSubmit = this.onFormSubmit.bind(this);

		this.validator = new SimpleReactValidator({
			// language customization
			/*  messages: {
            required: 'Пакажыце сапраўдны кантактны нумар',
            email: 'That is not an email.',
            default: 'koollll '
          }  */
		});
		this.state = {
			pet: null,
			primaryPhone: "",
			secondaryPhone: "",
			summary: "",
			data: null,
			service: A4P_SERVICE_TALKTOAVET,
			loading: true,
			submitBtnTxt: "Submit",
			serverErrors: false, // for displaying errors from server
			serverErrorMessage: "Sorry something went wrong" // error message frm server
		};
	}

	componentDidMount() {
		searchServices({
			service: this.state.service,
			offset: 0,
			limit: 1
		})
			.then(r => {
				console.log(r);
				if (r.data.vendor_services.length)
					this.setState({
						loading: false,
						data: r.data.vendor_services[0]
					});
				else this.setState({ loading: false, serverErrors: true });
			})
			.catch(err => {
				this.setState({ loading: false, serverErrors: true });
				console.log(err);
			});
	}

	onSelectChange(sel) {
		let s = sel.map(item => item.value);
		console.log(`Option selected:`, s);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onTSSelected(ts) {
		this.setState({ timeSlot: ts[0] });
	}

	onPetSelected(pet) {
		this.setState({ pet });
	}

	onFormSubmit(e) {
		e.preventDefault();

		if (this.validator.allValid()) {
			this.setState({
				submitBtnTxt: "Please Wait...",
				serverErrors: false,
				serverErrorMessage: ""
			});
			const { primaryPhone, secondaryPhone, pet } = this.state;

			const checkoutData = {
				paymentType: A4P_PAYMENT_TYPES.SERVICES,
				productId: this.state.service,
				vendorId: A4P_VENDOR_ID,
				vendorServiceId: this.state.data.id,
				for_ts: moment(this.state.timeSlot).toISOString(),
				details: {
					price: A4P_SERVICE_FEES.TALK_TO_VET,
					petId: pet.id,
					petName: pet.name,
					petType: pet.type,
					petBreed: pet.breed,
					phrId: pet.phr_id,
					primaryPhone,
					secondaryPhone,
					summary: this.state.summary
				}
			};
			setCheckoutData(checkoutData);
			this.props.history.push("/checkout");
		} else {
			this.validator.showMessages();
			this.forceUpdate(); // [validator] rerender to show messages for the first time
		}
	}

	render() {
		if (getUserRole() !== A4P_ROLE_USER) {
			return (
				<Redirect
					to={{
						pathname: "/login",
						redirectUrl: this.props.location.pathname
					}}
				/>
			);
		}

		const warningBlock = () => (
			<div className="alert alert-danger">
				<strong className="mr-2">Error!</strong>{" "}
				{this.state.serverErrorMessage}
			</div>
		);

		return (
			<div className="whiteHeaderOffset mb-5">
				<div className="container">
					<div className="row w-75 mx-auto">
						<h3 className="w-100 text-center mb-4">
							Talk To A Vet
						</h3>
						<div className="w-100 border-top">
							{this.state.serverErrors && warningBlock()}
							{this.state.loading ? (
								<p className="mt-5 text-center">loading....</p>
							) : (
								<form
									id="vsignup"
									method="post"
									onSubmit={this.onFormSubmit}
								>
									<div className="row">
										<p className="col-12 mt-4">
											Pet Details:
										</p>
										<div className="col-12 mt-2">
											<MyPets
												onPetSelected={
													this.onPetSelected
												}
											/>
										</div>
										{this.validator.message(
											"Pet",
											this.state.pet,
											"required"
										)}
									</div>
									<div className="row">
										<p className="col-12 mt-4">
											Contact Information:
										</p>
										<FormElement
											name="primaryPhone"
											className="col-md-6"
											placeHolder="Primary Contact Number"
											state={this.state}
											onChange={this.onChange}
											validator={this.validator}
											validation="required"
										/>
										<FormElement
											name="secondaryPhone"
											className="col-md-6"
											placeHolder="Secondary Contact [Optional]"
											state={this.state}
											onChange={this.onChange}
											validator={this.validator}
											validation=""
										/>
									</div>
									<div className="row">
										<p className="col-12 mt-4">
											Additional Information:
										</p>
										<FormElement
											name="summary"
											className="col-md-12"
											state={this.state}
											validator={this.validator}
											validation="required"
										>
											<textarea
												name="summary"
												rows="3"
												className="form-control"
												placeholder="Describe your pet condition in succinct as much as possible"
												onChange={this.onChange}
											/>
										</FormElement>
									</div>

									<div className="row">
										<p className="col-12 mt-4">
											Pick a Date and Time:
										</p>

										<div className="form-group">
											<DoctorSchedule
												selectedTimeSlots={
													this.state.data.roster
												}
												onTimeSlotSelected={
													this.onTSSelected
												}
												showCopyToWeeks={false}
												mode="pick"
											/>
											{this.validator.message(
												"Time Slot",
												this.state.timeSlot,
												"required"
											)}
										</div>
									</div>

									<div className="row justify-content-center my-3">
										<button
											type="submit"
											className="w-40 btn btn-primary btn-block"
											value="Submit"
										>
											{this.state.submitBtnTxt}
										</button>
									</div>
								</form>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
