import React, { Component } from "react";
import { Link } from "react-router-dom";
import config from "data/config";

export default class home extends Component {
	render() {
		return (
			<div>
				<div>
					{/* #Banner */}
					<div
						id="carouselPetParent"
						className="carousel slide"
						data-ride="carousel"
					>
						<ol className="carousel-indicators">
							<li
								data-target="#carouselPetParent"
								data-slide-to={0}
								className="active"
							/>
							<li
								data-target="#carouselPetParent"
								data-slide-to={1}
							/>
							<li
								data-target="#carouselPetParent"
								data-slide-to={2}
							/>
						</ol>
						<div className="carousel-inner">
							<div className="carousel-item active">
								<img
									src="/img/petParentbanner1c.jpg"
									className="d-block w-100 vh-100 bg-image"
									alt="..."
								/>
								<div className="container-fluid captop h-100 py-5 text-center">
									<div className="row align-items-center h-100">
										<div className="col-lg-12 mx-auto text-white text-center">
											<div className="carousel-caption">
												<h2>
													Empowering Pet Parents
													Everywhere
												</h2>
												<Link
													className="btn whitebtn text-uppercase rounded-pill mb-3 mb-md-0 "
													to="/talk-to-a-vet/"
												>
													Talk to a Vet
												</Link>
												<a
													href="https://services.activ4vets.com/Vet/AskQuestionStep1"
													target="_blank" rel="noopener noreferrer"
													className="btn whitebtn text-uppercase rounded-pill ml-md-3"
												>
													Ask A question
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="carousel-item">
								<img
									src="/img/petParentbanner2.jpg"
									className="d-block w-100 vh-100 bg-image"
									alt="..."
								/>
								<div className="container-fluid captop h-100 py-5 text-center">
									<div className="row align-items-center h-100">
										<div className="col-lg-12 mx-auto text-white text-center">
											<div className="carousel-caption">
												<h2>
													Take Control of Your Pets
													Wellbeing
												</h2>
												<Link
													className="btn whitebtn text-uppercase rounded-pill mb-3 mb-md-0 "
													to="/talk-to-a-vet/"
												>
													Talk to a Vet
												</Link>
												<a
													href="https://services.activ4vets.com/Vet/AskQuestionStep1"
													target="_blank" rel="noopener noreferrer"
													className="btn whitebtn text-uppercase rounded-pill ml-md-3"
												>
													Ask A question
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="carousel-item">
								<img
									src="/img/petParentbanner3.jpg"
									className="d-block w-100 vh-100 bg-image"
									alt="..."
								/>
								<div className="container-fluid captop h-100 py-5 text-center">
									<div className="row align-items-center h-100">
										<div className="col-lg-12 mx-auto text-white text-center">
											<div className="carousel-caption">
												<h2>
													Become An Awesome Pet Parent
												</h2>
												<Link
													className="btn whitebtn text-uppercase rounded-pill mb-3 mb-md-0 "
													to="/talk-to-a-vet/"
												>
													Talk to a Vet
												</Link>
												<a
													href="https://services.activ4vets.com/Vet/AskQuestionStep1"
													target="_blank" rel="noopener noreferrer"
													className="btn whitebtn text-uppercase rounded-pill ml-md-3"
												>
													Ask A question
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/*Services*/}
					<section className="bg-light py-5">
						<div className="container text-center py-2">
							<h2>Our Pet Parent Services</h2>
							<div className="row mt-5">
								<div className="col-sm-4 mb-md-0">
									<Link
										to="/vet-appointments/"
										className="bg-primary px-3 py-4 position-relative text-white d-block"
										style={{
											minHeight: "275px",
											textDecoration: "none",
											borderRadius: "10px"
										}}
									>
										<img
											src="/img/icons8-dog-64.png"
											alt="Pet Wellness App"
										/>
										<h5 className="mt-4 mb-3">
											Vet Appointments
										</h5>
										<div className="fs-1">
											Hassle free online Vet appointment
											booking system from Activ4pets. Beat
											the traffice, save time and instant
											confirmations
										</div>
									</Link>
								</div>
								<div className="col-sm-4 mb-5 mb-md-0">
									<Link
										to="/e-consultation/"
										className="bg-primary px-3 py-4 position-relative text-white d-block"
										style={{
											minHeight: "275px",
											textDecoration: "none",
											borderRadius: "10px"
										}}
									>
										<img
											src="/img/icons8-webcam-64_w.png"
											alt="eConsultation"
										/>
										<h5 className="mt-4 mb-3">
											eConsultation
										</h5>
										<div className="fs-1">
											Connect with a veterinarian via
											webcam using Activ4Pets platform,
											ensuring quality care no matter
											where you are
										</div>
									</Link>
								</div>
								<div className="col-sm-4 mb-5">
									<Link
										to="/vet-house-call/"
										className="bg-primary px-3 py-4 position-relative text-white d-block"
										style={{
											minHeight: "275px",
											textDecoration: "none",
											borderRadius: "10px"
										}}
									>
										<img
											src="/img/vetIcon.png"
											alt="Vet House Call"
										/>
										<h5 className="mt-4 mb-3">
											Vet House Call
										</h5>
										<div className="fs-1">
											Personalized, quality, in-home pet
											care service that your pet will
											love. The best vet care in the
											comfort of your home.
										</div>
									</Link>
								</div>

								<div className="clearfix" />
							</div>
						</div>
					</section>
					{/*mission*/}
					<div className="container py-5">
						<div className="row py-2">
							<div className="col-sm-4">
								<div className="leftBx">
									<strong>
										We are on a mission to make pet
										healthcare more convenient and
										accessible than ever before.
									</strong>
								</div>
							</div>
							<div className="col-sm-8">
								<div className="row h-sertab">
									<div className="col-sm-4 mb-5 mb-md-0">
										<div
											className="text-center d-block text-dark bg-light px-3 py-4"
											style={{
												minHeight: "320px",
												textDecoration:
													"none !important"
											}}
										>
											<img
												src="/img/smartphone.png"
												alt="mobile-app"
												className="w-50"
											/>
											<h5 className="mt-3">
												Everything you need for your pet
												care in the palm of your hands
											</h5>
										</div>
									</div>
									<div className="col-sm-4 mb-5 mb-md-0">
										<div
											className="text-center d-block text-dark bg-light px-3 py-4"
											style={{
												minHeight: "320px",
												textDecoration:
													"none !important"
											}}
										>
											<img
												src="/img/vet.png"
												alt="mobile-app"
												className="w-50"
											/>
											<h5 className="mt-3">
												On Demand Veterinary Advice From
												the comfort of your home
											</h5>
										</div>
									</div>
									<div className="col-sm-4">
										<div
											className="text-center d-block text-dark bg-light px-3 py-4"
											style={{
												minHeight: "320px",
												textDecoration:
													"none !important"
											}}
										>
											<img
												src="/img/phr.png"
												alt="mobile-app"
												className="w-50"
											/>
											<h5 className="mt-3">
												24/7 worldwide access to your
												pet’s health history &amp;
												medications
											</h5>
										</div>
									</div>
									<div className="clearfix" />
								</div>
							</div>
							<div className="clearfix" />
						</div>
					</div>
					{/*How it work*/}
					<section className="bg-light py-5">
						<div className="container text-center my-4">
							<h2 className="mb-5 pb-4">
								The Way It Works Is Really Simple
							</h2>
							<div className="row mt-5">
								<div className="col-sm-4  mb-5 mb-md-0">
									<div
										className="bg-white px-3 py-4 position-relative"
										style={{ minHeight: "275px" }}
									>
										<div className="cointSteps rounded-circle">
											1
										</div>
										<h5 className="mt-5 mb-3 text-primary">
											Sign Up
										</h5>
										<div className="fs-1">
											Sign up for an Activ4Pets
											Membership, our staffcontact your
											veterinarian/s to retrieve medical
											records and build up a comprehensive
											profile for your pet
										</div>
									</div>
								</div>
								<div className="col-sm-4  mb-5 mb-md-0">
									<div
										className="bg-white px-3 py-4 position-relative"
										style={{ minHeight: "275px" }}
									>
										<div className="cointSteps rounded-circle">
											2
										</div>
										<h5 className="mt-5 mb-3 text-primary">
											Access Your Pets Health Record
										</h5>
										<div className="fs-1">
											Login to the Activ4Pets platform or
											download our mobile app to access
											your Pet’s Health Record whenever
											you need to. You can login anywhere
											in the world.
										</div>
									</div>
								</div>
								<div className="col-sm-4">
									<div
										className="bg-white px-3 py-4 position-relative"
										style={{ minHeight: "275px" }}
									>
										<div className="cointSteps rounded-circle">
											3
										</div>
										<h5 className="mt-5 mb-3 text-primary">
											Start Using Our Services
										</h5>
										<div className="fs-1">
											E-Consultations are great way to
											connect with your vet if you need
											advice outside regular office hours.
											Peer-to-Peer Consults expand
											treatment and diagnosis options for
											your vet.
										</div>
									</div>
								</div>
								<div className="clearfix" />
							</div>
						</div>
					</section>
					<div className="container py-5">
						<div className="text-center py-2">
							<h2 className="mb-5">
								Want to Know More? Watch This
							</h2>
							<a href="#!" className="vp-a">
								<img
									className="img-fluid"
									src="/img/vidMockup1.jpg"
									alt="Online Vets"
								/>
							</a>
						</div>
					</div>
					{/*Footer*/}
				</div>
			</div>
		);
	}
}
