import React, { Component } from "react";
import SectionHead from "../sectionHead";
import { searchBookings } from "./network";
import isEmpty from "utils/isEmpty";
import AppointmentListItem from "./ListItem";
import Button from "pages/components/form/Button";
import AppointmentsFilters from "./sidebar";
import AppointmentDetails from "./details";
import { A4P_APPOINTMENT_STATUSES } from "data/config/variables";
import moment from "moment-timezone";

class AppointmentsIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            endOfList: false,
            showDetails: false,
            isNew: false,
            loading: true,
            listing: null,
            status: null,
            sortBy: null,
            timezone: null,
            bookings: [],
            bookingId: null,
            selBooking: null
        };

        this.fetchAppointments = this.fetchAppointments.bind(this);
        this.reset = this.reset.bind(this);
    }

    // Retreive appointments
    fetchAppointments() {
        const { bookings, limit, offset } = this.state;
        this.setState({ loading: true });
        searchBookings(this.state)
            .then(r => {
                const li = r.data.bookings;
                if (!isEmpty(li))
                    this.setState({
                        loading: false,
                        //bookings: [...bookings, ...li],
                        bookings: bookings.concat(li),
                        offset: offset + limit,
                        endOfList: li.length < limit
                    });
                else this.setState({ loading: false, endOfList: true });
            })
            .catch(err => console.log(err));
    }

    reset(params) {
        this.setState(
            { ...params, bookings: [], offset: 0, endOfList: false },
            this.fetchAppointments
        );
    }

    render() {
        const {
            showDetails,
            loading,
            endOfList,
            bookings,
            timezone,
            selBooking,
            isNew
        } = this.state;

        return (
            <div>
                {showDetails && (
                    <AppointmentDetails
                        booking={selBooking}
                        isNew={isNew}
                        timezone={timezone}
                        onGoBack={v => {
                            this.setState({
                                selBooking: null,
                                showDetails: false,
                                isNew: false
                            });
                            if (v) this.reset({}); // on cancellation reload
                        }}
                    />
                )}

                {!showDetails && (
                    <div className="mainc container-fluid">
                        <SectionHead
                            title="My Appointments"
                            createTxt="New Appointment"
                            onCreateClick={e =>
                                this.setState({
                                    showDetails: true,
                                    isNew: true
                                })
                            }
                        />

                        <div>
                            <div
                                className="row"
                                style={{
                                    display: selBooking ? "none" : "flex"
                                }}
                            >
                                <div className="col-sm-12 col-md-3 mb-3">
                                    <AppointmentsFilters
                                        onUpdate={p => this.reset(p)}
                                    />
                                </div>
                                <div className="col-sm-12 col-md-9">
                                    {!loading && isEmpty(bookings) ? (
                                        <p className="my-2 lead p-3 border">
                                            No Appointments Found
                                        </p>
                                    ) : (
                                        <div>
                                            <div className="row">
                                                {bookings.map((b, k) => (
                                                    <AppointmentListItem
                                                        key={k}
                                                        timezone={timezone}
                                                        booking={b}
                                                        onClick={b =>
                                                            this.setState({
                                                                selBooking: b,
                                                                showDetails: true,
                                                                isNew: false
                                                            })
                                                        }
                                                    />
                                                ))}
                                            </div>
                                            {!endOfList && (
                                                <div className="text-center mt-4">
                                                    <Button
                                                        btnCls="btn-outline-secondary"
                                                        loading={loading}
                                                        onClick={
                                                            this
                                                                .fetchAppointments
                                                        }
                                                        value="load more"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export const RenderStatus = (slot, timezone, status) => {
    const badge = (cls, title) => (
        <span className={`badge ${cls} px-2`} style={{ fontWeight: "100" }}>
            {title}
        </span>
    );

    if (status === A4P_APPOINTMENT_STATUSES.CANCELED)
        return badge("badge-danger", "Canceled");
    else if (status === A4P_APPOINTMENT_STATUSES.RESCHEDULED)
        return badge("badge-info", "Re-Scheduled");
    else if (moment.tz(timezone).isBefore(slot))
        return badge("badge-success", "Active");
    return badge("badge-warning", "Completed");
};

export default AppointmentsIndex;
