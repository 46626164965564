import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";

import config from "data/config";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import home from "./home";
import forVet from "./for-vet";
import downloadApp from "./download-app";
import PublicLogin from "./login";

import vetAppointments from "./services/vet-appointments";
import checkout from "./checkout";
import vetHouseCall from "./services/vet-house-call";
import eConsultation from "./services/e-consultation";
import vetList from "./services/vet-appointments/listing";
import NotFound from "./404";
import petMedicalRrecords from "./pet-medical-records";
import vetPracticeManagementSolution from "./vet-practice-management-solution";
import becomeActiv4petsVet from "./become-activ4pets-vet";
import partnerReliefVet from "./partner-relief-vet";
import about from "./about";
import leadershipTeam from "./leadership-team";
import ourPartners from "./our-partners";
import shelterPartnership from "./shelter-partnership";
import faqs from "./faqs";
import contactUs from "./contact-us";
import privacyPolicy from "./privacy-policy";
import termsConditions from "./terms-conditions";
import ScrollToTop from "pages/components/ScrollToTop";
import VetSignup from "./for-vet/signup";
import TalkToAVet from "./services/talk-to-a-vet";
import SMO from "./services/second-medical-opinion";
import HouseVisitRequest from "./services/vet-house-call/request";
import TalkToVetRequest from "./services/talk-to-a-vet/request";
import SMORequest from "./services/second-medical-opinion/request";
import EConsultationRequest from "./services/e-consultation/request";
import SubHome from "./subHome";
import { getVendorSite } from "./network";
import { updateSubDomainInStore } from "utils/LoginChecker";
import { getDomainName } from "utils";

const LoadHeader = ({
    component: Component,
    auth,
    noHeader,
    showTransparentBG,
    ...rest
}) => (
    <Route
        {...rest}
        render={props => (
            <div>
                {!noHeader && (
                    <Header
                        loggedIn={auth.isAuthenticated}
                        showTransparentBG={showTransparentBG}
                    />
                )}
                <Component {...props}  />
                {!noHeader && <Footer  />}
            </div>
        )}
    />
);

class PublicHome extends Component {
    constructor(props) {
        super(props);

        let customDomain = window.location.host;
        let subDomain = null; 

        if(customDomain.includes(getDomainName(config.SITE_URL))) {
            const parts = customDomain.split(".");
            const [sub] = parts;            
            // subdomain and length is greater than 3
            subDomain = (parts.length === 3 && sub.length > 3) ? sub : null;
            customDomain = null;
        }      
        
        this.state = {
            subDomain,
            customDomain,
            loadingSubdomain: Boolean(customDomain || subDomain),
            siteNotFound: false,
        };
        
        if(this.state.loadingSubdomain) {
          getVendorSite({subDomain, customDomain})
            .then(r=>{
              const [sd] = r.data.websites;
              if(sd) {
                updateSubDomainInStore(sd);
                this.setState({loadingSubdomain: false});                
              }
              else this.setState({loadingSubdomain: false, siteNotFound: true});
            })
            .catch(err=>console.log(err))
        }
    }

    render() {
        const { subDomain, customDomain, loadingSubdomain, siteNotFound } = this.state; 
        const auth = this.props.auth;
        return (
            <ScrollToTop>
                {subDomain || customDomain ? (
                    loadingSubdomain?(
                      <h5 className="p-2 text-muted"><i className="fa fa-spinner fa-spin mr-1"></i> loading...</h5>
                    ):( siteNotFound? (
                      <h5 className="p-2 text-muted">Site not found</h5>
                        ):(
                          <Switch>
                            <LoadHeader path="/" exact auth={auth}  component={SubHome} /> 
                            <LoadHeader path="/vendor/login" exact auth={auth}  component={VetSignup} />
                            <LoadHeader path="/login" exact auth={auth}  component={PublicLogin} />
                            <LoadHeader path="/signup" exact auth={auth}  component={PublicLogin} />
                            <LoadHeader path="/privacy-policy/" auth={auth} exact component={privacyPolicy} /> 
                            <LoadHeader path="/terms-conditions/" auth={auth} exact component={termsConditions} />
                            <LoadHeader auth={auth} component={NotFound} />  
                          </Switch>
                        )
                    )
                ) : (
                    <Switch>
                      <LoadHeader path="/" exact auth={auth} showTransparentBG={true} component={home} /> 
                      <LoadHeader path="/for-vet/" exact auth={auth} showTransparentBG={false} component={forVet} />
                      <LoadHeader path="/for-vet/signup" exact auth={auth} component={VetSignup} />
                      <LoadHeader path="/vendor/login" exact auth={auth} component={VetSignup} />
                      <LoadHeader path="/login" exact auth={auth} component={PublicLogin} />
                      <LoadHeader path="/signup" exact auth={auth} component={PublicLogin} />
              
                      <LoadHeader path="/download-app/" exact auth={auth} component={downloadApp} />
                      <LoadHeader path="/vet-appointments/" exact auth={auth} component={vetAppointments} />
                      <LoadHeader path="/vet-appointments/list" exact auth={auth} component={vetList} />
                      <LoadHeader path="/vet-house-call/" auth={auth} exact component={vetHouseCall} />  
                      <LoadHeader path="/vet-house-call/order" auth={auth} exact component={HouseVisitRequest} />  
                      <LoadHeader path="/e-consultation/" auth={auth} exact component={eConsultation} />         
                      <LoadHeader path="/e-consultation/order" auth={auth} exact component={EConsultationRequest} />     
                      <LoadHeader path="/talk-to-a-vet/" auth={auth} exact component={TalkToAVet} />     
                      <LoadHeader path="/talk-to-a-vet/order" auth={auth} exact component={TalkToVetRequest} />     
                      <LoadHeader path="/second-medical-opinion/" auth={auth} exact component={SMO} />     
                      <LoadHeader path="/second-medical-opinion/order" auth={auth} exact component={SMORequest} />     
                      <LoadHeader path="/pet-medical-records/" auth={auth} exact component={petMedicalRrecords} />   
                      
                      <LoadHeader path="/vet-practice-management-solution/" auth={auth} exact component={vetPracticeManagementSolution} /> 
                      <LoadHeader path="/become-activ4pets-vet/" auth={auth} exact component={becomeActiv4petsVet} /> 
                      <LoadHeader path="/partner-relief-vet/" auth={auth} exact component={partnerReliefVet} /> 
                      
                      <LoadHeader path="/about/" auth={auth} exact component={about} /> 
                      <LoadHeader path="/leadership-team/" auth={auth} exact component={leadershipTeam} /> 
                      <LoadHeader path="/our-partners/" auth={auth} exact component={ourPartners} /> 
                      <LoadHeader path="/shelter-partnership/" auth={auth} exact component={shelterPartnership} /> 
                      <LoadHeader path="/faqs/" auth={auth} exact component={faqs} /> 
                      <LoadHeader path="/privacy-policy/" auth={auth} exact component={privacyPolicy} /> 
                      <LoadHeader path="/terms-conditions/" auth={auth} exact component={termsConditions} /> 
                      <LoadHeader path="/contact-us/" auth={auth} exact component={contactUs} /> 
              
                      <LoadHeader path="/checkout" auth={auth} exact noHeader={true} component={checkout} /> 
                
                      <LoadHeader auth={auth} component={NotFound} />  
                    </Switch>
                )}
            </ScrollToTop>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, null)(PublicHome);
