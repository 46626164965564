import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import SocialLogin from "./social-logins";
import SimpleReactValidator from "simple-react-validator";
import ReCaptcha from "pages/components/form/ReCaptcha";
import FormElement from "pages/components/form/FormElement";
import { A4P_ROLE_USER } from "data/config/variables";
import parseGQLError, { GQL_ERROR_DUPLICATE } from "utils/HasuraParser";
import { signup, fetchCoupon } from "./network";
import * as qs from "query-string";
import { detect } from "detect-browser";
import MobileVerify from "pages/components/auth/MobileVerify";

class Signup extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onCaptchaSubmit = this.onCaptchaSubmit.bind(this);
        this.fetchCoupon = this.fetchCoupon.bind(this);

        this.validator = new SimpleReactValidator({
            // language customization
            /*  messages: {
        required: 'Пакажыце сапраўдны кантактны нумар',
        email: 'That is not an email.',
        default: 'koollll '
      }  */
        });

        this.initialState = {
            role: this.props.role,
            first_name: "",
            last_name: "",
            password: "",
            email: "",
            phone: null,

            showPromoForm: false, // to show promo code form
            promoCode: null,
            showPromoStatus: false,
            isPromoCodeValid: false,
            promoDescription: null,
            planId: null,

            isCaptchaValid: false,
            submitBtnTxt: "Submit",
            serverErrors: false, // for displaying errors from server
            serverErrorMessage: "Sorry! Something went wrong", // error message frm server
            thankYou: false // determines if form got submitted successfully
        };
        this.state = { ...this.initialState };
    }

    componentDidMount() {
        const coupon = qs.parse(this.props.location.search).cc;
        if (coupon) {
            this.setState({ showPromoForm: true, promoCode: coupon }, () =>
                this.fetchCoupon()
            );
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value.trim() });
    }

    onFormSubmit(e) {
        e.preventDefault();
        //this.setState({ thankYou: true }); return;

        if (this.validator.allValid()) {
            this.setState({
                submitBtnTxt: "Please Wait...",
                serverErrors: false,
                serverErrorMessage: ""
            });

            // get browser info
            const browser = detect();
            const source = {
                medium: "Web",
                browser: browser.name,
                version: browser.version,
                os: browser.os
            };

            signup({ ...this.state, source })
                .then(r => {
                    this.props.onSignupComplete(this.state);
                })
                .catch(err => {
                    err =
                        typeof err === "object"
                            ? parseGQLError(err.response.data.data.errors)
                            : err;
                    this.setState({
                        submitBtnTxt: "Submit",
                        serverErrors: true,
                        serverErrorMessage:
                            err === GQL_ERROR_DUPLICATE
                                ? "This email already exists in our System."
                                : "Sorry! Something went wrong. Please try again later."
                    });
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate(); // [validator] rerender to show messages for the first time
        }
    }

    // validate the entered coupon
    fetchCoupon() {
        const { promoCode } = this.state;
        this.setState({ planId: null, showPromoStatus: false });
        if (promoCode) {
            fetchCoupon(promoCode)
                .then(r => {
                    console.log(r);
                    const [plan] = r.data.plans;
                    if (plan)
                        this.setState({
                            planId: plan.id,
                            showPromoStatus: true,
                            isPromoCodeValid: true,
                            promoDescription: plan.description
                        });
                    else
                        this.setState({
                            showPromoStatus: true,
                            isPromoCodeValid: false,
                            promoDescription: null
                        });
                })
                .catch(err => console.log(err));
        }
    }

    onCaptchaSubmit(value) {
        this.setState({ isCaptchaValid: true });
    }

    render() {
        const warningBlock = () => (
            <div className="alert alert-danger">
                <strong className="mr-2">Error!</strong>
                {this.state.serverErrorMessage}
            </div>
        );

        const {
            showPromoForm,
            showPromoStatus,
            isPromoCodeValid,
            promoDescription
        } = this.state;
        const { showCaptcha, showCoupon, showLoginLink } = this.props;

        return (
            <div>
                <h4 className="text-center mb-4">Signup below</h4>
                <div className="box_form">
                    <div>
                        {this.state.serverErrors && warningBlock()}
                        <form
                            id="vsignup"
                            method="post"
                            onSubmit={this.onFormSubmit}
                        >
                            <div className="row">
                                <FormElement
                                    className="col-sm-12 col-md-6"
                                    name="first_name"
                                    placeHolder="First Name"
                                    state={this.state}
                                    onChange={this.onChange}
                                    validator={this.validator}
                                    validation="required|alpha"
                                />

                                <FormElement
                                    name="last_name"
                                    className="col-sm-12 col-md-6"
                                    placeHolder="Last Name"
                                    state={this.state}
                                    onChange={this.onChange}
                                    validator={this.validator}
                                    validation="required|alpha"
                                />

                                <FormElement
                                    name="email"
                                    className="col-sm-12"
                                    placeHolder="Email"
                                    state={this.state}
                                    onChange={this.onChange}
                                    validator={this.validator}
                                    validation="required|email"
                                />

                                <div className="col-sm-12">
                                    <MobileVerify
                                        onVerify={phone =>
                                            this.setState({ phone })
                                        }
                                    />
                                    {this.validator.message(
                                        "Mobile",
                                        this.state.phone,
                                        "required"
                                    )}
                                </div>

                                <FormElement
                                    name="password"
                                    type="password"
                                    className="col-sm-12"
                                    placeHolder="Password"
                                    state={this.state}
                                    onChange={this.onChange}
                                    validator={this.validator}
                                    validation="required|min:8"
                                />
                            </div>
                            {showCoupon && (
                                <div>
                                    <hr />
                                    {!showPromoForm ? (
                                        <p className="col-12 text-right link_bright">
                                            <a
                                                href="#0"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.setState({
                                                        showPromoForm: true
                                                    });
                                                }}
                                            >
                                                Have a Promo Code?
                                            </a>
                                        </p>
                                    ) : (
                                        <div className="row">
                                            <div className="col-10">
                                                <FormElement
                                                    name="promoCode"
                                                    className=""
                                                    placeHolder="Promo Code"
                                                    state={this.state}
                                                    onChange={this.onChange}
                                                    validator={this.validator}
                                                    validation=""
                                                />
                                            </div>
                                            <div className="col-2 pl-0">
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary"
                                                    onClick={this.fetchCoupon}
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                            {showPromoStatus &&
                                                (isPromoCodeValid ? (
                                                    <div className="ml-3">
                                                        <div className="alert alert-success">
                                                            <i className="fa fa-tick"></i>
                                                            <strong>
                                                                Promo Applied!
                                                            </strong>
                                                            <p
                                                                className="mt-2"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: promoDescription
                                                                }}
                                                            ></p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="ml-3">
                                                        <div className="alert alert-danger">
                                                            <strong className="mr-2">
                                                                Error!
                                                            </strong>
                                                            Invalid code
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                </div>
                            )}

                            {showCaptcha && (
                                <div className="row">
                                    <div
                                        className="col-md-12"
                                        style={{ overflow: "hidden" }}
                                    >
                                        <div className="form-group">
                                            <ReCaptcha
                                                onChange={this.onCaptchaSubmit}
                                            />
                                            {this.validator.message(
                                                "captcha",
                                                this.state.isCaptchaValid,
                                                "accepted"
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="form-group text-center mt-3">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                >
                                    {this.state.submitBtnTxt}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                {showLoginLink && (
                    <p className="text-center link_bright mb-2">
                        <span>
                            Alraedy have an account?{" "}
                            <a
                                href="#0"
                                onClick={() => this.props.onLoginClick()}
                            >
                                <strong>Login here!</strong>
                            </a>
                        </span>
                    </p>
                )}
            </div>
        );
    }
}

Signup.defaultProps = {
    role: A4P_ROLE_USER
};

Signup.propTypes = {
    onLoginClick: PropTypes.func.isRequired,
    onSignupComplete: PropTypes.func.isRequired,
    role: PropTypes.string
};

export default withRouter(Signup);
