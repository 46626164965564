import React, { Component } from "react";
import SectionHead from "../../components/sectionHead";
import { A4P_PHR_PREFIX } from "data/config/variables";
import MyPets from "pages/components/MyPets";

class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      submitBtnTxt: "Submit",
      serverError: null,
      userId: this.props.userId,
      pet: null,
      loading: false
    };
    this.onPetSelected = this.onPetSelected.bind(this);
  }

  onPetSelected(pet) {
    console.log(pet);
    this.setState({ pet, loading: true });
  }

  render() {
    const { pet, userId, loading, serverError } = this.state;
    return (
      <div className="mainc container-fluid">
        <SectionHead title="User Pet Details" onGoBack={this.props.onGoBack} />
       
        {userId && (
          <div className="mt-3">
            <div className="col-12">
              <MyPets userId={userId} onPetSelected={this.onPetSelected} />
            </div>
            {loading && <p className="col-12">loading...</p>}
            {pet && (
              <iframe
                title="Pet Medical Records"
                src={`${A4P_PHR_PREFIX + pet.phr_id}`}
                frameBorder="0"
                onLoad={() => this.setState({ loading: false })}
                style={{
                  width: "100%",
                  border: "0",
                  height: "70vh"
                }}
              />
            )}
          </div>
        )}
        
      </div>
    );
  }
}

export default UserDetails;
