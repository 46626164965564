/* import React, { Component } from "react";

export default class about extends Component {
    render() {
        return (
            <main>
                <div className="position-relative my-5 pageTitle">
                    <img className="img-fluid" src="/img/vetBanner.jpg" alt="inner page bg" />
                    <div className="position-absolute w-100 text-center text-white page-tagline">
                        <h1 className="w-70 m-auto pt-5 pb-5">About Activ4Pets</h1>
                    </div>
                </div>
                <div className="container py-5">
                    <p>
                        Activ4Pets is the first company of its kind, enabling pet owners to access their animal’s
                        complete health history and even consult with their veterinarian online – all via an easy to use
                        web-based platform.
                    </p>
                    <p>
                        Activ4Pets was founded in 2014 by Businessman and Entrepreneur Florent Monssoh, with the vision
                        of empowering owners to take better control of their pet’s health. We provide three
                        comprehensive services – Pet Health Records, e-Consultations and Peer-to-Peer Consults – to help
                        owners care for their animals.
                    </p>
                    <p>
                        Activ4Pets encourages owners to become more engaged in their pet’s wellness. Our platform acts
                        as a drop box for your pet’s health – storing every vaccination, allergy, test result and more –
                        accessible anytime, anywhere on the planet. With our mobile app you’ll never be more than a
                        click away from your pet’s wellness information, and your veterinarian.
                    </p>
                    <p>
                        Meanwhile, our e-Consultation service allows you to speak with your veterinarian from the
                        comfort of home. As well as reducing the overall stress associated with traveling with your
                        animal, Activ4Pets has made pet healthcare more convenient and accessible than ever before.
                    </p>
                    <p>
                        <strong>Unconditional Love Deserves Unconditional Care</strong>
                    </p>
                    <p>
                        Our mission is to allow people and families all over the world to live safer, healthier and more
                        productive lives – and this, naturally, includes the well being of our four-legged family
                        members. Our pets show us unconditional love so it makes sense that we show them the same.
                    </p>
                    <p>
                        Activ4Pets is the sister company of Activ Doctors Online (ADO), a global telemedicine company
                        with over 2,000,000 human members. With offices in Paris, Miami, Madrid, Tianjin (China) and
                        Pune (India), ADO has been a telemedicine trailblazer and a partner to consumers, employers,
                        healthcare providers and life insurers.
                    </p>
                </div>
            </main>
        );
    }
} */

import React, { Component } from "react";
import BookAppointment from "pages/components/BookAppointment";
import { A4P_VENDOR_ID } from "data/config/variables";
import * as qs from "query-string";

export default class HouseVisitRequest extends Component {
	render() {
        const vendor = qs.parse(this.props.location.search).vendor;
        const vendor_id = vendor || A4P_VENDOR_ID;
		return (
			<div className="whiteHeaderOffset mb-5">
				<div className="container">
					<BookAppointment vendor_id={vendor_id} />
				</div>
			</div>
		);
	}
}
