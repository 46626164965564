import React, { Component } from "react";
import BookAppointmentSlot from "./slot";
import BookAppointmentPayment from "./payment";
import BookAppointmentConfirmation from "./confirmation";
import BookAppointmentListing from "./listing";
import BookAppointmentInfo from "./information";
import Button from "../form/Button";
import { getConsultationName } from "utils";
import isEmpty from "utils/isEmpty";
import moment from "moment-timezone";
import { fetchBookedSlots } from "./network";

export default class BookAppointment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendor_id: this.props.vendor_id,
            error: false,
            currStage: this.STAGES.listing,
            listing: null,
            isSingleListing: false,
            bookedSlots: [],
            slot: null,
            info: null
        };
        this.fetchBookedSlots = this.fetchBookedSlots.bind(this);
    }

    STAGES = { listing: 1, slot: 2, info: 3, payment: 4, confirmation: 5 };

    fetchBookedSlots() {
        const { listing } = this.state;
        fetchBookedSlots(listing.id)
            .then(r => {
                const li = r.data.bookings;
                if (!isEmpty(li)) {
                    const fLi = li.map(l => l.details.slot);
                    this.setState({ bookedSlots: fLi });
                }
            })
            .catch(err => console.log(err));
    }

    getSummary = () => {
        const { listing, slot, info, currStage, isSingleListing } = this.state;
        let summary = [];

        if (listing) {
            summary.push({
                title: "Listing",
                value: listing.title,
                editEnabled: !isSingleListing,
                state: {
                    currStage: this.STAGES.listing,
                    listing: null,
                    slot: null,
                    info: null
                }
            });
        }

        if (slot) {
            summary.push({
                title: "Slot",
                editEnabled: true,
                value: `${getConsultationName(slot.service.type)}, ${moment
                    .tz(slot.time, slot.timezone)
                    .format("Do dddd, MMMM hh:mm A")}`,
                state: { currStage: this.STAGES.slot, slot: null, info: null }
            });
        }

        if (info) {
            summary.push({
                title: "Information",
                value: info.summary,
                editEnabled: true,
                value: `${info.pet ? `Pet: ${info.pet.name},` : ""} Summary: ${
                    info.summary
                }`,
                state: { currStage: this.STAGES.info }
            });
        }

        return summary;
    };

    render() {
        const {
            listing,
            slot,
            bookedSlots,
            info,
            currStage,
            error,
            vendor_id
        } = this.state;
        let summary = this.getSummary();

        return (
            <div>
                {error ? (
                    <div className="col p-5 my-5 text-center text-muted h2">
                        Sorry! Something went wrong.
                    </div>
                ) : (
                    <div>
                        <div className="px-3 border">
                            <div className="my-3">
                                <p className="h6 text-muted">
                                    Booking Summary:
                                </p>
                            </div>
                            {!isEmpty(summary) &&
                                currStage !== this.STAGES.confirmation &&
                                summary.map((it, k) => (
                                    <div key={k} style={{ height: "3em" }}>
                                        <span
                                            className="text-muted mr-3"
                                            style={{
                                                width: "5em",
                                                display: "inline-block"
                                            }}
                                        >
                                            {it.title}:
                                        </span>
                                        <span
                                            style={{
                                                fontSize: "1.3em",
                                                cursor: "pointer"
                                            }}
                                            onClick={e =>
                                                it.editEnabled
                                                    ? this.setState({
                                                          ...it.state
                                                      })
                                                    : false
                                            }
                                        >
                                            <span className="lead">
                                                {it.value}{" "}
                                            </span>
                                            {it.editEnabled && (
                                                <i
                                                    title="edit"
                                                    className="ml-2 fa fa-edit text-primary"
                                                />
                                            )}
                                        </span>
                                    </div>
                                ))}
                        </div>

                        {currStage === this.STAGES.listing && (
                            <BookAppointmentListing
                                listing={listing}
                                vendor_id={vendor_id}
                                onSelect={(
                                    listing,
                                    isSingleListing = false
                                ) => {
                                    if (listing)
                                        this.setState(
                                            {
                                                listing,
                                                currStage: currStage + 1,
                                                isSingleListing
                                            },
                                            this.fetchBookedSlots
                                        );
                                    else this.setState({ error: true });
                                }}
                            />
                        )}
                        {currStage === this.STAGES.slot && (
                            <BookAppointmentSlot
                                slot={slot}
                                listing={listing}
                                bookedSlots={bookedSlots}
                                onSelect={slot =>
                                    this.setState({
                                        slot,
                                        currStage: currStage + 1
                                    })
                                }
                            />
                        )}
                        {currStage === this.STAGES.info && (
                            <BookAppointmentInfo
                                info={info}
                                onComplete={info =>
                                    this.setState({
                                        info,
                                        currStage: currStage + 1
                                    })
                                }
                            />
                        )}
                        {currStage === this.STAGES.payment && (
                            <BookAppointmentPayment
                                listing={listing}
                                slot={slot}
                                info={info}
                                onComplete={val =>
                                    this.setState({ currStage: currStage + 1 })
                                }
                            />
                        )}
                        {currStage === this.STAGES.confirmation && (
                            <BookAppointmentConfirmation />
                        )}
                    </div>
                )}
            </div>
        );
    }
}
