import React, { Component } from "react";
import { Redirect } from "react-router";
import SimpleReactValidator from "simple-react-validator";
import FormElement from "pages/components/form/FormElement";
import {
  A4P_VENDOR_ID,
  A4P_SERVICE_SMO,
  profile_pic_types,
  A4P_SERVICE_FEES,
  A4P_ROLE_USER,
  A4P_PAYMENT_TYPES
} from "data/config/variables";
import { setCheckoutData, getUserRole } from "utils";
import MyDropZone from "pages/components/form/MyDropZone";
import MyPets from "pages/components/MyPets";

export default class SMORequest extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onPetSelected = this.onPetSelected.bind(this);
    this.onDZUploadFiles = this.onDZUploadFiles.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);

    this.validator = new SimpleReactValidator({
      // language customization
      /*  messages: {
            required: 'Пакажыце сапраўдны кантактны нумар',
            email: 'That is not an email.',
            default: 'koollll '
          }  */
    });
    this.state = {
      pet: null,
      files: null,
      firstOpinion: null,
      authorize: null,
      query: null,
      service: A4P_SERVICE_SMO,
      loading: false,
      submitBtnTxt: "Submit",
      serverErrors: false, // for displaying errors from server
      serverErrorMessage: "Sorry something went wrong" // error message frm server
    };
  }

  onSelectChange(sel) {
    let s = sel.map(item => item.value);
    console.log(`Option selected:`, s);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  // callback from Dropzone after uploading files
  onDZUploadFiles(files) {
    this.setState({ files: [...files] });
  }

  onPetSelected(pet) {
    this.setState({ pet });
  }

  onFormSubmit(e) {
    e.preventDefault(); 
    if (this.validator.allValid()) {
      this.setState({
        submitBtnTxt: "Please Wait...",
        serverErrors: false,
        serverErrorMessage: ""
      });

      const checkoutData = {
        paymentType : A4P_PAYMENT_TYPES.SERVICES,
        productId: this.state.service,
        vendorId: A4P_VENDOR_ID,
        vendorServiceId: 1, // dummy
        for_ts: null,
        details: {
          price: A4P_SERVICE_FEES.SMO,
          petId: this.state.pet.id,
          petName: this.state.pet.name,
          petType: this.state.pet.type,
          petBreed: this.state.pet.breed,
          phrId: this.state.pet.phr_id,
          firstOpinion: this.state.firstOpinion,
          query: this.state.query,
          files: this.state.files
        }
      };
      setCheckoutData(checkoutData);
      this.props.history.push("/checkout");
    } else {
      this.validator.showMessages();
      this.forceUpdate(); // [validator] rerender to show messages for the first time
    }
  }

  render() {
    
    if (getUserRole() !== A4P_ROLE_USER) {
      return (
        <Redirect
          to={{
            pathname: "/login",
            redirectUrl: this.props.location.pathname
          }}
        />
      );
    }

    const warningBlock = () => (
      <div className="alert alert-danger">
        <strong className="mr-2">Error!</strong> {this.state.serverErrorMessage}
      </div>
    );

    return (
      <div className="whiteHeaderOffset mb-5">
        <div className="container">
          <div className="row w-75 mx-auto">
            <h3 className="w-100 text-center mb-4">
              Request a Second Medical Opinion
            </h3>
            <div className="w-100 border-top">
              {this.state.serverErrors && warningBlock()}
              {this.state.loading ? (
                <p className="mt-5 text-center">loading....</p>
              ) : (
                <form id="vsignup" method="post" onSubmit={this.onFormSubmit}>
                  <div className="row">
                    <p className="col-12 mt-4">Select A Pet:</p>
                    <div className="col-12 mt-2">
                      <MyPets onPetSelected={this.onPetSelected} />
                    </div>
                    {this.validator.message("Pet", this.state.pet, "required")}

                  </div>
                  <div className="row">
                    <p className="col-12 mt-4">Additional Information:</p>
                    <FormElement
                      name="firstOpinion"
                      className="col-md-12"
                      state={this.state}
                      validator={this.validator}
                      validation="required"
                    >
                      <textarea
                        name="firstOpinion"
                        rows="3"
                        className="form-control"
                        placeholder="Provide details of the first opinion"
                        onChange={this.onChange}
                      />
                    </FormElement>
                    <FormElement
                      name="query"
                      className="col-md-12"
                      state={this.state}
                      validator={this.validator}
                      validation="required"
                    >
                      <textarea
                        name="query"
                        rows="3"
                        className="form-control"
                        placeholder="Please state your question for which your are seeking second opinion"
                        onChange={this.onChange}
                      />
                    </FormElement>
                  </div>

                  <div className="row">
                    <p className="col-12 mt-4">Pick a Date and Time:</p>

                    <div style={{ width: "96%", margin: "0 15px 20px", }}>
                      <MyDropZone
                        placeHolder="Drag and Drop files here or Click to browse"
                        onUpload={this.onDZUploadFiles}
                        uploadType={profile_pic_types.serviceSMO}
                        acceptTypes= "*"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 form-group">
                      <input
                        id="ck_authorize"
                        type="checkbox" 
                        onChange={(e) => {
                          this.setState({authorize: this.state.authorize?null:true})
                        }}
                      />
                      <label for="ck_authorize" className="ml-2">
                        I authorize Activ4Pets and it authorized vertenerians to access my pets medical records
                      </label>
                      {this.validator.message(
                        "authorize",
                        this.state.authorize,
                        "required"
                      )}
                      
                    </div> 
                  </div>

                  <div className="row justify-content-center my-3">
                    <button
                      type="submit"
                      className="w-40 btn btn-primary btn-block"
                      value="Submit"
                    >
                      {this.state.submitBtnTxt}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
