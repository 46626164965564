import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { setupAuth } from "utils/LoginChecker";
import PublicRoute from "pages/public";
import UserRoute from "pages/app";
import RTCRoute from "pages/rtc";
import config from "data/config";

// check for login
setupAuth();

// enable disabled console.log
if (config.ENV === "production")
	console.log = console.warn = console.error = () => {};

function App() { 
	return (
		<Router>
			<Switch>
				{/* WebRTC Route */}
				<Route path="/videochat" component={RTCRoute} />

				{/* User Routes */}
				<Route path="/app" component={UserRoute} />

				{/* Public Routes */}
				<Route path="/" component={PublicRoute} />
			</Switch>
		</Router>
	);
}

export default App;
