import React, { Component } from "react";
import { getConsultationName, getConsultationIcon } from "utils";

export default class BookAppointmentService extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selIdx: null, 
        };
    } 
	
    render() {
        const { selIdx } = this.state;
        const { listing } = this.props;
        const { consultations } = listing || {};

        // remove disabled consultations
        const enabledConsultations = consultations.filter( c => c.status )

        return (
            <div>
                <div className="mb-3">
                    <p className="h6 text-muted my-3">Select Service:</p>
                </div>
                <div className="d-flex  flex-wrap">
                    {enabledConsultations &&
                        enabledConsultations.map((c, k) => (
                            <div
                                key={k}
                                className={`shadow-sm rounded  p-3 mb-3 mr-3 ${
                                    selIdx === k ? "bg-primary text-white" : "bg-white text-primary"
                                }`}
                                onClick={e => {
									this.setState({ selIdx: k });
									this.props.onSelect(c)
								}}
                                style={{
                                    width: "fit-content",
                                    cursor: "pointer"
                                }}
                            >
                                <div className="d-flex align-items-center ">
                                    <div>
                                        <i
                                            className={`fa mr-3 ${getConsultationIcon(c.type)}`}
                                            style={{ fontSize: "1.5em" }}
                                        ></i>
                                    </div>
                                    <div className="flex-grow">
                                        <div className="text-center lead mb-1">${c.price}</div>
                                        <div className="">{getConsultationName(c.type)}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        );
    }
}
