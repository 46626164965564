import React, { Component } from "react";
import * as qs from "query-string";
import config from "data/config";

export default class RTCRoute extends Component {

    constructor(){
        super();
        this.state={loading: true}
    }

	render() {
        const {loading} = this.state;
        let conf = qs.parse(this.props.location.search).conf;
        if(!conf) conf = "test123";

		return (
			<div className="">
                {loading && <p className="mt-3 col-12">loading....</p>}
				<iframe
					allow="geolocation; microphone; camera"
					//src={`https://login.activ4pets.com/rtc/index.html?conf=${this.props.data.id}`}
					src= {`https://tokbox.com/embed/embed/ot-embed.js?embedId=${config.TOKBOX_EMBED_ID}&room=${conf}&iframe=true`}
					scrolling="auto"
                    frameBorder="0"
                    onLoad={()=>this.setState({loading: false})}
					style={{
						width: "100%", 
						height: "96vh"
					}}
				/>
			</div>
		);
	}
}
